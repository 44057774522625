/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_hu from './hu/common.json';

export default {
  en: {
    common: common_en,
  },
  hu: {
    common: common_hu,
  },
};
