import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const errorTranslate = (msg, t) => {
  const msgTranslated = t(msg);
  return msgTranslated;
};

function RenderTextarea({
  t, input, meta: { touched, error },
}) {
  return (
    <div className="form__form-group-input-wrap">
      <textarea {...input} />
      {touched && error && <span className="form__form-group-error">{errorTranslate(error, t)}</span>}
    </div>
  );
}

RenderTextarea.propTypes = {
  t: PropTypes.func.isRequired,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderTextarea.defaultProps = {
  meta: null,
};

export default withTranslation('common')(RenderTextarea);
