import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_TASK = 'LOAD_TASK';
export const LOAD_TASK_LIST = 'LOAD_TASK_LIST';
export const ADD_TASK = 'ADD_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const SET_TASK_FILES = 'SET_TASK_FILES';
export const TASK_ERROR = 'TASK_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_TASK = 'SET_ALL_TASK';

const configFile = {
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
};

export const setTaskFiles = (taskId) => async (dispatch, getState) => {
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=task&f-table_id=${taskId}&f-file_type=document&rowsPerPage=200`;
  const files = await axios.get(filesPath);
  const { item } = getState().task;

  item.fileList = files.data.list;

  dispatch({
    type: SET_TASK_FILES,
    item,
  });
};

export const loadTask = (taskId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/tasks/${taskId}`;
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=task&f-table_id=${taskId}&f-file_type=document&rowsPerPage=200`;

  if (taskId !== '') {
    try {
      axios.get(filesPath).then((files) => {
        axios.get(path).then((res) => {
          const task = res.data;
          const item = task;

          item.fileList = files.data.list;

          dispatch({
            type: LOAD_TASK,
            item,
          });
        });
      });
      return;
    } catch (err) {
      dispatch({
        type: TASK_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_TASK,
      item: getState().task.defaultItem,
    });
  }
};

export const loadTaskList = () => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/tasks${window.location.search}`;

  const { defaultFilters } = getState().task;
  const defaultFiltersClone = { ...defaultFilters };
  const filters = Object.assign(defaultFiltersClone, getFilterFromUrl());

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'date_from', 'desc');
        dispatch({
          type: LOAD_TASK_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: TASK_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addTask = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/tasks`, body);
    const taskId = res.data.id;
    const fileUrl = `${process.env.REACT_APP_API_URL}/api/files/task/${res.data.id}/document`;

    if (typeof post.files !== 'undefined') {
      const { files } = post;

      const uploaders = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', 'codeinfuse, medium, gist');
        formData.append('timestamp', (Date.now() / 1000) || 0);

        return axios.post(fileUrl, formData, configFile)
          .then(() => {
          }).catch((error) => {
            dispatch(setAlert(`${file.name} - ${error.response.data.detail}`, 'danger', 20000));
          });
      });

      axios.all(uploaders).then(() => {
        dispatch(setTaskFiles(taskId));
      });
    }

    dispatch({
      type: ADD_TASK,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres űrlap mentés!', 'success'));
    dispatch(loadTaskList());
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editTask = (task) => async (dispatch) => {
  const dataUrl = `${process.env.REACT_APP_API_URL}/api/tasks/${task.id}`;
  const fileUrl = `${process.env.REACT_APP_API_URL}/api/files/task/${task.id}/document`;

  const body = JSON.stringify(task);

  try {
    const res = await axios.put(dataUrl, body);

    if (typeof task.files !== 'undefined') {
      const { files } = task;

      const uploaders = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', 'codeinfuse, medium, gist');
        formData.append('timestamp', (Date.now() / 1000) || 0);

        return axios.post(fileUrl, formData, configFile)
          .then(() => {
          }).catch((error) => {
            dispatch(setAlert(`${file.name} - ${error.response.data.detail}`, 'danger', 20000));
          });
      });

      axios.all(uploaders).then(() => {
        dispatch(setTaskFiles(task.id));
      });
    } else {
      dispatch(setTaskFiles(task.id));
    }

    dispatch({
      type: EDIT_TASK,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres űrlap mentés!', 'success'));
    dispatch(loadTaskList());
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().task;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudTask'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allTask = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/tasks/all-task`;

  try {
    axios.get(path)
      .then((res) => {
        const { all_task } = res.data;
        dispatch({
          type: SET_ALL_TASK,
          all_task,
        });
      }).catch((err) => {
        dispatch({
          type: TASK_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
