import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';

function SidebarLink({
  title, icon, route, onClick,
}) {
  return (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
    >
      <li className="sidebar__link" title={title}>
        <SVG src={icon} />
      </li>
    </NavLink>
  );
}

SidebarLink.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  title: '',
  icon: '',
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
