import React from 'react';
import SidebarContent from './SidebarContent';

function Sidebar() {
  return (
    <div className="sidebar">
      <SidebarContent
        onClick={() => {}}
      />
    </div>
  );
}

export default Sidebar;
