import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Container, Row } from 'reactstrap';
import BasicMap from './components/BasicMap';
import { getPatrolTrackSession } from '../../redux/actions/authActions';

const useFetch = (current) => {
  const [devices, setDevices] = useState({ ErrorMessage: null, DeviceData: null });
  const dispath = useDispatch();
  const mounted = useRef();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    async function updatePatrolData() {
      if (current !== null) {
        const body = JSON.stringify({ SessionId: current.SessionId });
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/patrols/updateData`, body);
        setDevices(res.data);
        if (res.data.ErrorMessage === 'Unauthorized') {
          dispath(getPatrolTrackSession());
        }
      }
    }

    if (!mounted.current) {
      mounted.current = true;
    } else {
      updatePatrolData();
      const intervalId = setInterval(() => {
        updatePatrolData();
      }, 5000);// until 600000 ms the session is valid
      return () => clearInterval(intervalId);
    }
  }, [current, dispath]);

  return devices;
};

const useInitSess = () => {
  const patrolSession = useSelector((state) => state.auth.patrolSession);
  const [ref, setRef] = useState(null);
  useEffect(() => {
    if (patrolSession !== null) {
      setRef(patrolSession);
    }
  }, [patrolSession]);

  return ref;
};

function GoogleMap() {
  const loadSession = useInitSess();
  const data = useFetch(loadSession);

  return (
    <Container className="dashboard">
      <div className="dashboard__map">
        <Row noGutters>
          <BasicMap devices={data.DeviceData} />
        </Row>
      </div>
    </Container>
  );
}

export default GoogleMap;
