import axios from 'axios';

export const SET_ALL_UNIT = 'SET_ALL_UNIT';
export const UNIT_ERROR = 'UNIT_ERROR';

export const allUnit = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/units/all-unit`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_unit } = res.data;
        dispatch({
          type: SET_ALL_UNIT,
          all_unit,
        });
      }).catch((err) => {
        dispatch({
          type: UNIT_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: UNIT_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
