import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import RenderField from '../../../shared/components/form/RenderField';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import { countries } from '../../../shared/utility/baseData';

class HeadOffice extends PureComponent {
  render() {
    return (
      <div>
        <h6 className="modal-sub-title">SZÉKHELY ADATAI</h6>

        <Row>
          <Col xs={12} sm={6} md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Ország</span>
              <div className="form__form-group-select">
                <Field
                  name="country"
                  component={renderSelectFieldwithSelected}
                  options={countries}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Irányítószám</span>
              <div className="form__form-group-field">
                <Field
                  name="zip_code"
                  component={RenderField}
                  type="number"
                  parse={(value) => Number(value)}
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Város</span>
              <div className="form__form-group-field">
                <Field
                  name="city"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Közterület, házszám</span>
              <div className="form__form-group-field">
                <Field
                  name="street"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Telefonszám</span>
              <div className="form__form-group-field">
                <Field
                  name="phone_number"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HeadOffice;
