import React, { PureComponent } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import SelectImage from './SelectImage';
import Notices from './Notices';

class TrackTabs extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: '1',
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { activeTab } = this.state;

    return (
      <div className="tabs tracking-tabs">
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                Napló
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                Kamerakép
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Notices />
            </TabPane>
            <TabPane tabId="2">
              <SelectImage />
            </TabPane>
          </TabContent>
        </div>
      </div>

    );
  }
}

export default TrackTabs;
