import React, { PureComponent, Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import DynamicTableHead from './DynamicTableHead';
import RowContent from './RowContent';
import TablePaginationActions from './TablePaginationActions';

import { setUrlQuery } from '../../utility/urlModify';

class DynamicTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: '',
      toggle: false,
    };
  }

  handleRequestSort = (event, property) => {
    let newOrder = 'desc';
    const { updateList, order, orderBy } = this.props;

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setUrlQuery('page', 0);
    setUrlQuery('order', newOrder);
    setUrlQuery('orderBy', property);

    updateList();
  };

  handleChangePage = (event, page) => {
    const { updateList } = this.props;
    setUrlQuery('page', page);
    updateList();
  };

  handleChangeRowsPerPage = (event) => {
    const { updateList } = this.props;
    setUrlQuery('page', 0);
    setUrlQuery('rowsPerPage', event.target.value);
    updateList();
  };

  setActiveRow = (id, showToggle) => {
    const { activeRow } = this.state;
    let newActiveRow = '';

    if (activeRow !== id) {
      newActiveRow = id;
    }

    this.setState(() => ({ activeRow: newActiveRow, toggle: showToggle }));
  }

  render() {
    const {
      data,
      headrows,
      order,
      orderBy,
      totalCount,
      rowsPerPage,
      page,
      edit,
      exportDocumentum,
      trackRecord,
      addSubListItem,
      operations,
      ToggleContent,
      colSpan,
    } = this.props;

    const { activeRow, toggle } = this.state;

    return (
      <div>
        <div className="material-table__wrap">
          <Table className="material-table">
            <DynamicTableHead
              headrows={headrows}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              operations={operations}
            />
            <TableBody>
              {data.map((row) => (
                <Fragment key={row.id}>

                  <RowContent
                    row={row}
                    headrows={headrows}
                    operations={operations}
                    edit={edit}
                    exportDocumentum={exportDocumentum}
                    trackRecord={trackRecord}
                    addSubListItem={addSubListItem}
                    setActiveRow={this.setActiveRow}
                    activeRow={activeRow}
                  />

                  {toggle && row.id === activeRow && (
                  <TableRow
                    className="table-row toggle-row"
                    key={`${row.id}_toggle`}
                  >
                    <TableCell className="cell-content-toggle" key={`${row.id}_ToggleContent`} colSpan={colSpan}>
                      { ToggleContent(row.id) }
                    </TableCell>
                  </TableRow>
                  )}

                </Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="material-table__pagination">
          <TablePagination
            component="div"
            classes={{ root: 'tablePagination' }}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            labelRowsPerPage="Rekord / oldal"
            labelDisplayedRows={({ from, to }) => `Találatok ${from}-${to} / ${totalCount}`}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </div>
    );
  }
}

DynamicTable.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  updateList: PropTypes.func.isRequired,
  edit: PropTypes.func,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
  ToggleContent: PropTypes.func,
  exportDocumentum: PropTypes.func,
  trackRecord: PropTypes.func,
  addSubListItem: PropTypes.func,
  colSpan: PropTypes.number,
};

DynamicTable.defaultProps = {
  edit: () => undefined,
  ToggleContent: () => undefined,
  exportDocumentum: () => undefined,
  trackRecord: () => undefined,
  addSubListItem: () => undefined,
  colSpan: 0,
};

export default DynamicTable;
