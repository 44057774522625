import React from 'react';
import PropTypes from 'prop-types';

function DeviceInfoBox({ device }) {
  return (
    <div className="info-box-wrap">
      <div className="info-box">
        <div className="table-responsive info-box-bg">
          <table className="table">
            <thead className="thead-blue">
              <tr>
                <th scope="col">Jármű</th>
                <th scope="col">Azonosító</th>
                <th scope="col">Bejelzés időpontja</th>
                <th scope="col">Szélességi fok</th>
                <th scope="col">Hosszúsági fok</th>
                <th scope="col">Sebesség</th>
                <th scope="col">Irány</th>
                <th scope="col">Műhold</th>
                <th scope="col">Hálózat</th>
                <th scope="col">Akku</th>
                <th scope="col">Gyújtás</th>
                <th scope="col">Online</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{device.licencePlateNumber}</td>
                <td>{device.id}</td>
                <td>{device.lastUpdateTime}</td>
                <td>{device.position.lat}</td>
                <td>{device.position.lng}</td>
                <td>{device.speed} km/h</td>
                <td>{device.degree}°</td>
                <td>{device.gpsSats}</td>
                <td>{device.gsmNetwork}</td>
                <td>{device.accuVoltage}</td>
                <td><span className={`point point${device.isIgnitionOn ? '-true' : '-false'}`} /></td>
                <td><span className={`point point${device.isOnline ? '-true' : '-false'}`} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

DeviceInfoBox.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number,
    licencePlateNumber: PropTypes.string,
    lastUpdateTime: PropTypes.string,
    position: PropTypes.shape(),
    speed: PropTypes.number,
    degree: PropTypes.number,
    gpsSats: PropTypes.number,
    gsmNetwork: PropTypes.string,
    accuVoltage: PropTypes.number,
    isIgnitionOn: PropTypes.bool,
    isOnline: PropTypes.bool,
  }).isRequired,
};

export default DeviceInfoBox;
