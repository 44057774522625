import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import {
  addSubTask, editSubTask, modifyModal, loadSubTask,
} from '../../../redux/actions/subTaskActions';
import { allTask } from '../../../redux/actions/taskActions';
import { allAccounts } from '../../../redux/actions/accountsActions';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import RenderTextarea from '../../../shared/components/form/RenderTextarea';
import { taskStatuses } from '../../../shared/utility/baseData';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDSubTask = class CRUDSubTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadSubTask());
    }
    dispatch(allTask());
    dispatch(allAccounts());
    dispatch(modifyModal());
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit, tasks, accounts,
    } = this.props;

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {itemId === '' ? 'Új részfeladat' : `Részfeladat szerkesztése #${itemId}`}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={handleSubmit(itemId === '' ? add : edit)} noValidate>
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Részfeladat státusz</span>
                      <div className="form__form-group-field">
                        <Field
                          name="status"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={taskStatuses}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} sm={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat azonosító</span>
                      <div className="form__form-group-field">
                        <Field
                          name="task_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={tasks}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm={4} md={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Munkatárs</span>
                      <div className="form__form-group-select">
                        <Field
                          name="account_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={accounts}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Mennyiség</span>
                      <div className="form__form-group-field">
                        <Field
                          name="quantity"
                          component={RenderField}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat kezdete</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_task"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat vége</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_task_end"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Részletes leírás</span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          component={RenderTextarea}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDSubTask.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  accounts: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
};

CRUDSubTask.defaultProps = {
  itemId: '',
  tasks: [],
  accounts: [],
};

const mapStateToProps = (state) => ({
  initialValues: state.subTask.item,
  modal: state.subTask.modal,
  itemId: state.subTask.item.id,
  tasks: state.task.all_task,
  accounts: state.accounts.all_accounts,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudSubTask',
  enableReinitialize: true,
})(CRUDSubTask);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addSubTask, edit: editSubTask },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
