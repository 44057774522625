import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { removeAlert } from '../../redux/actions/alertActions';

const close = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

function Alertv2({ alerts, lockable, closeClick }) {
  return alerts !== null && alerts.length > 0 && alerts.map((alert) => (
    <div key={alert.id} className={`alert alert-${alert.alertType}`}>
      <div className="alert-msg">
        { alert.msg }
        {lockable
        && (
          <button type="button" className="alert-close-btn" aria-label="Close" onClick={(e) => closeClick(e, alert.id)}>
            <SVG src={close} />
          </button>
        )}
      </div>
    </div>
  ));
}

Alertv2.propTypes = {
  alerts: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  closeClick: (e, Id) => {
    e.preventDefault();
    dispatch(removeAlert(Id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Alertv2);
