import {
  SET_ALL_UNIT,
  UNIT_ERROR,
} from '../actions/unitActions';

const initialState = {
  all_unit: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case SET_ALL_UNIT: {
      const { all_unit } = action;
      return {
        ...state,
        all_unit,
      };
    }
    case UNIT_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    default:
      return state;
  }
};
