import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import { setHeader, setAuthToken } from '../../shared/utility/setAxios';
import { loadAuth } from '../../redux/actions/authActions';

setHeader();
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
i18next.init(i18nextConfig);

function App() {
  useEffect(() => {
    store.dispatch(loadAuth());
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <ScrollToTop>
            <div>
              <Router />
            </div>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
