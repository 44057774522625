import axios from 'axios';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_TRACK_LIST = 'LOAD_TRACK_LIST';
export const TRACK_ERROR = 'TRACK_ERROR';
export const SET_TRACK_FILES = 'SET_TRACK_FILES';
export const SET_TRACK_NOTICES = 'SET_TRACK_NOTICES';

export const loadTrackList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/tracks${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'name');
        dispatch({
          type: LOAD_TRACK_LIST,
          payload,
          filters,
        });
      });
  } catch (err) {
    dispatch({
      type: TRACK_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const setTrackFiles = (accountId) => async (dispatch, getState) => {
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=account&f-table_id=${accountId}&order=desc&orderBy=upload_date&page=0&rowsPerPage=40`;
  const files = await axios.get(filesPath);
  const { item } = getState().track;

  const currList = files.data.list;
  const selectedOptions = [];

  currList.forEach((file) => {
    const option = {};
    option.value = file.link;
    option.label = file.upload_date;
    selectedOptions.push(option);
  });

  item.fileList = selectedOptions;
  item.image = selectedOptions.length > 0 ? selectedOptions[0].value : '';

  dispatch({
    type: SET_TRACK_FILES,
    item,
  });
};

export const setTrackNotices = (accountId) => async (dispatch, getState) => {
  // eslint-disable-next-line max-len
  const path = `${process.env.REACT_APP_API_URL}/api/notices?f-account_id=${accountId}&rowsPerPage=5&order=desc&orderBy=create_date`;
  const notices = await axios.get(path);
  const { item } = getState().track;

  item.notices = notices.data.list;

  dispatch({
    type: SET_TRACK_NOTICES,
    item,
  });
};
