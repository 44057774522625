import queryString from 'query-string';

export const deleteFilterFromUrl = function deleteFilterFromUrl() {
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const searchUrl = queryString.parse(window.location.search);
  const urlWithoutFilter = {};

  Object.entries(searchUrl).forEach(([key, value]) => {
    if (key.substring(0, 2) !== 'f-') {
      Object.defineProperty(urlWithoutFilter, key, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  });

  const nextSerarchUrl = queryString.stringify(urlWithoutFilter);
  const newurl = `${currentUrl}?${nextSerarchUrl}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const getFilterFromUrl = function getFilterFromUrl() {
  const searchUrl = queryString.parse(window.location.search);
  const urlFilter = {};

  Object.entries(searchUrl).forEach(([key, value]) => {
    if (key.substring(0, 2) === 'f-') {
      const newKey = key.substring(2);
      Object.defineProperty(urlFilter, newKey, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  });

  return urlFilter;
};

export const setFilterToUrl = function setFilterToUrl(filter) {
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const searchUrl = queryString.parse(window.location.search);

  Object.entries(filter).forEach(([key, value]) => {
    if (value === null || value.trim() === '' || value.trim() === '__ALL__') {
      return;
    }

    Object.defineProperty(searchUrl, `f-${key}`, {
      value,
      writable: true,
      enumerable: true,
      configurable: true,
    });
  });

  const nextSerarchUrl = queryString.stringify(searchUrl);
  const newurl = `${currentUrl}?${nextSerarchUrl}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const setUrlQuery = function setUrlQuery(key, values) {
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const searchUrl = queryString.parse(window.location.search);

  Object.defineProperty(searchUrl, key, {
    value: values,
    writable: true,
    enumerable: true,
    configurable: true,
  });

  const nextSerarchUrl = queryString.stringify(searchUrl);
  const newurl = `${currentUrl}?${nextSerarchUrl}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const setTableSettings = function setTableSettings(data, orderBy = 'id', order = 'asc') {
  const payload = {};
  const query = queryString.parse(window.location.search);
  const { totalCount, list } = data;
  const page = typeof query.page !== 'undefined' ? query.page : 0;
  const rpp = typeof query.rowsPerPage !== 'undefined' ? query.rowsPerPage : 10;

  payload.totalCount = totalCount;
  payload.data = list;
  payload.order = typeof query.order !== 'undefined' ? query.order : order;
  payload.orderBy = typeof query.orderBy !== 'undefined' ? query.orderBy : orderBy;
  payload.rowsPerPage = parseInt(rpp, 10);
  payload.page = parseInt(page, 10);

  return payload;
};
