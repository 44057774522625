import React from 'react';
import {
  Container, Col, Row, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadTaskList, loadTask, modifyModal,
} from '../../redux/actions/taskActions';
import { clearAlert } from '../../redux/actions/alertActions';
import { allPartners } from '../../redux/actions/partnerActions';
import DynamicTable from '../../shared/components/dynamicTable/DynamicTable';
import TaskFilter from './components/TaskFilter';
import CRUDTask from './components/CRUDTask';
import ColleagueSubTaskList from './components/ColleagueSubTaskList';
import { deleteFilterFromUrl, setFilterToUrl, setUrlQuery } from '../../shared/utility/urlModify';
import { allContracts } from '../../redux/actions/contractActions';
import { allAccounts } from '../../redux/actions/accountsActions';
import { allAddresses } from '../../redux/actions/addressActions';
import { allMaterials } from '../../redux/actions/materialActions';
import { allTaskTypes } from '../../redux/actions/taskTypeActions';

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };
    const { dispatch } = this.props;
    dispatch(clearAlert());
    dispatch(loadTaskList());
  }

  updateList = () => {
    const { dispatch } = this.props;
    dispatch(loadTaskList());
  }

  setFilter = (post) => {
    deleteFilterFromUrl();
    setFilterToUrl(post);
    setUrlQuery('page', 0);
    this.updateList();
  }

  load = (id) => {
    const { dispatch } = this.props;

    dispatch(allContracts());
    dispatch(allAccounts());
    dispatch(allAddresses());
    dispatch(allMaterials());
    dispatch(allTaskTypes());
    dispatch(allPartners());
    dispatch(loadTask(id));
    dispatch(modifyModal());

    this.setState(() => ({
      itemId: id,
    }));
  }

  ToggleContent = (id) => {
    const { list } = this.props;

    const record = list.find((o) => o.id === id);

    const subList = typeof record.sublist !== 'undefined' ? record.sublist : [];
    const unit = typeof record.unit !== 'undefined' ? record.unit : '';

    return (
      <ColleagueSubTaskList list={subList} unit={unit} />
    );
  }

  render() {
    const {
      list, order, headrows, operations, orderBy, totalCount, rowsPerPage, page,
    } = this.props;

    const { itemId } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={10} lg={10} xl={10} className="container-list">
            <Card>
              <CardBody>
                <CRUDTask itemId={itemId} onSubmit={this.save} />
                <DynamicTable
                  headrows={headrows}
                  operations={operations}
                  data={list}
                  showCheckBox={false}
                  order={order}
                  orderBy={orderBy}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  ref={this.tableChildUpdate}
                  updateList={this.updateList}
                  edit={this.load}
                  exportDocumentum={this.exportDocumentum}
                  ToggleContent={this.ToggleContent}
                  colSpan={10}
                />
              </CardBody>
            </Card>
          </Col>
          <TaskFilter onSubmit={this.setFilter} />
        </Row>
      </Container>
    );
  }
}

Tasks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  list: state.task.data,
  order: state.task.order,
  orderBy: state.task.orderBy,
  headrows: state.task.headrows,
  operations: state.task.operations,
  totalCount: state.task.totalCount,
  rowsPerPage: state.task.rowsPerPage,
  page: state.task.page,
});

export default connect(mapStateToProps)(Tasks);
