import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import {
  addTaskType, editTaskType, modifyModal, loadTaskType,
} from '../../../redux/actions/taskTypeActions';
import { allUnit } from '../../../redux/actions/unitActions';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDTaskType = class CRUDTaskType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadTaskType());
    }

    dispatch(allUnit());
    dispatch(modifyModal());
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit, units,
    } = this.props;

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {itemId === '' ? 'Új feladattípus' : `Feladattípus szerkesztése #${itemId}`}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={handleSubmit(itemId === '' ? add : edit)} noValidate>
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladattípus neve</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Mértékegység</span>
                      <div className="form__form-group-select">
                        <Field
                          name="unit"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={units}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDTaskType.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
};

CRUDTaskType.defaultProps = {
  itemId: '',
  units: [],
};

const mapStateToProps = (state) => ({
  initialValues: state.taskType.item,
  modal: state.taskType.modal,
  itemId: state.taskType.item.id,
  units: state.unit.all_unit,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudTaskType',
  enableReinitialize: true,
})(CRUDTaskType);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addTaskType, edit: editTaskType },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
