import {
  LOAD_MATERIAL_LIST, LOAD_MATERIAL, ADD_MATERIAL, EDIT_MATERIAL,
  MATERIAL_ERROR, MODIFY_MODAL, CLEAR, SET_ALL_MATERIALS,
} from '../actions/materialActions';

const initialState = {
  order: 'asc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  defaultItem: {},
  modal: false,
  filters: {},
  operations: ['edit'],
  headrows: [
    {
      id: 'id',
      type: 'value',
      label: 'Azonosító',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'name',
      type: 'value',
      label: 'Anyag neve',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_MATERIAL_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case LOAD_MATERIAL: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case ADD_MATERIAL: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_MATERIAL: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case MATERIAL_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    case MODIFY_MODAL: {
      const { modal } = action;
      return {
        ...state,
        modal,
      };
    }
    case CLEAR:
      return {
        ...state,
        item: {},
        error: {},
      };
    case SET_ALL_MATERIALS: {
      const { all_materials } = action;
      return {
        ...state,
        all_materials,
      };
    }
    default:
      return state;
  }
};
