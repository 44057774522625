const defaultPositions = [
  { value: 'SERVICE', label: 'Szervíz' },
  { value: 'DRIVER', label: 'Sofőr' },
  { value: 'MINEWORK', label: 'Kődaráló' },
];

const defaultStatuses = [
  { value: 'ACTIVE', label: 'Aktív' },
  { value: 'PASSIVE', label: 'Passzív' },
];

const countries = [
  { value: 'Ausztria', label: 'Ausztria' },
  { value: 'Magyarország', label: 'Magyarország' },
  { value: 'Németország', label: 'Németország' },
];

const addressTypes = [
  // { value: 'HEAD_OFFICE', label: 'Székhely' },
  { value: 'ESTABLISHMENT', label: 'Telephely' },
  { value: 'SHIPPING', label: 'Szállítási' },
  { value: 'SPECIAL', label: 'Egyedi' },
];

const deviceStatuses = [
  { value: 'ACTIVE', label: 'Aktív' },
  { value: 'PASSIVE', label: 'Passzív' },
  { value: 'IN_SERVICE', label: 'Szervizben' },
];

const deviceFilterStatuses = [
  { value: '__ALL__', label: 'Összes' },
  { value: 'Aktív', label: 'Aktív' },
  { value: 'Passzív', label: 'Passzív' },
  { value: 'Szervizben', label: 'Szervizben' },
];

const deviceTypes = [
  { value: 'Homlokemelő', label: 'Homlokemelő' },
  { value: 'Targonca', label: 'Targonca' },
  { value: 'Teherautó', label: 'Teherautó' },
];

const deviceFilterTypes = [
  { value: '__ALL__', label: 'Összes' },
  { value: 'Homlokemelő', label: 'Homlokemelő' },
  { value: 'Targonca', label: 'Targonca' },
  { value: 'Teherautó', label: 'Teherautó' },
];

const priorityTypes = [
  { value: 'URGENT', label: 'Sürgős' },
  { value: 'HIGH', label: 'Magas' },
  { value: 'MEDIUM', label: 'Közepes' },
  { value: 'LOW', label: 'Alacsony' },
];

const priorityFilterTypes = [
  { value: '__ALL__', label: 'Összes' },
  { value: 'Sürgős', label: 'Sürgős' },
  { value: 'Magas', label: 'Magas' },
  { value: 'Közepes', label: 'Közepes' },
  { value: 'Alacsony', label: 'Alacsony' },
];

const taskStatuses = [
  { value: 'NEW', label: 'Új' },
  { value: 'IN PROGRESS', label: 'Folyamatban' },
  { value: 'STOP', label: 'Szüneteltetve' },
  { value: 'DONE', label: 'Megoldva' },
];

exports.defaultPositions = defaultPositions;
exports.defaultStatuses = defaultStatuses;
exports.countries = countries;
exports.addressTypes = addressTypes;
exports.deviceStatuses = deviceStatuses;
exports.deviceFilterStatuses = deviceFilterStatuses;
exports.deviceTypes = deviceTypes;
exports.deviceFilterTypes = deviceFilterTypes;
exports.priorityTypes = priorityTypes;
exports.priorityFilterTypes = priorityFilterTypes;
exports.taskStatuses = taskStatuses;
