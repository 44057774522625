import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SVG from 'react-inlinesvg';

const editIcon = `${process.env.PUBLIC_URL}/img/EditIcon.svg`;
const excelIcon = `${process.env.PUBLIC_URL}/img/ExcelIcon.svg`;

class ContractModificationList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { list, edit, exportDocumentum } = this.props;

    return (
      <>
        {list.length === 0 && (
        <div className="empty-content"> A szerződésnek nincs módosítása.
        </div>
        )}

        {list.length > 0 && (
        <div className="material-table__wrap">
          <Table className="material-table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell
                  key="id_head"
                  align="left"
                >
                  Azon.
                </TableCell>
                <TableCell
                  key="company"
                  align="left"
                >
                  Cég neve
                </TableCell>
                <TableCell
                  key="subject"
                  align="left"
                >
                  Szerződés tárgya
                </TableCell>
                <TableCell
                  key="date_from"
                  align="left"
                >
                  Szerződés kezdete
                </TableCell>
                <TableCell
                  key="date_to"
                  align="left"
                >
                  Szerződés vége
                </TableCell>
                <TableCell
                  key="operations"
                  align="left"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow
                  className="table-row"
                  key={row.id}
                >
                  <TableCell key={`${row.id}_id`} className="cell-content-text" align="left">
                    <div>{row.id}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_company`} className="cell-content-text" align="left">
                    <div>{row.company}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_subject`} className="cell-content-text" align="left">
                    <div>{row.subject}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_date_from`} className="cell-content-text" align="left">
                    <div>{row.date_from}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_date_to`} className="cell-content-text" align="left">
                    <div>{row.date_to}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_buttons`} className="cell-content-operations" align="left">
                    <button className="" value={row.id} onClick={() => edit(row.id)} type="button">
                      <SVG src={editIcon} />
                    </button>
                    <button className="" value={row.id} onClick={() => exportDocumentum(row.id)} type="button">
                      <SVG src={excelIcon} />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        )}
      </>
    );
  }
}

ContractModificationList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  edit: PropTypes.func.isRequired,
  exportDocumentum: PropTypes.func.isRequired,
};

export default ContractModificationList;
