import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import {
  addMaterial, editMaterial, modifyModal, loadMaterial,
} from '../../../redux/actions/materialActions';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDMaterial = class CRUDMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadMaterial());
    }
    dispatch(modifyModal());
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit,
    } = this.props;

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {itemId === '' ? 'Új anyag' : `Anyag szerkesztése #${itemId}`}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={handleSubmit(itemId === '' ? add : edit)} noValidate>
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Anyag neve</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDMaterial.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
};

CRUDMaterial.defaultProps = {
  itemId: '',
};

const mapStateToProps = (state) => ({
  initialValues: state.material.item,
  modal: state.material.modal,
  itemId: state.material.item.id,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudMaterial',
  enableReinitialize: true,
})(CRUDMaterial);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addMaterial, edit: editMaterial },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
