import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_DEVICE = 'LOAD_DEVICE';
export const LOAD_DEVICE_LIST = 'LOAD_DEVICE_LIST';
export const ADD_DEVICE = 'ADD_DEVICE';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const DEVICE_ERROR = 'DEVICE_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_DEVICE = 'SET_ALL_DEVICE';

export const loadDevice = (deviceId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/devices/${deviceId}`;

  if (deviceId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const item = res.data;

          dispatch({
            type: LOAD_DEVICE,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: DEVICE_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_DEVICE,
      item: getState().device.defaultItem,
    });
  }
};

export const loadDeviceList = () => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/devices${window.location.search}`;
  const { defaultFilters } = getState().device;
  const defaultFiltersClone = { ...defaultFilters };
  const filters = Object.assign(defaultFiltersClone, getFilterFromUrl());

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'name');
        dispatch({
          type: LOAD_DEVICE_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: DEVICE_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addDevice = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/devices`, body);

    dispatch({
      type: ADD_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadDeviceList());
  } catch (err) {
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editDevice = (device) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/devices/${device.id}`;
  const body = JSON.stringify(device);

  try {
    const res = await axios.put(newUrl, body);

    dispatch({
      type: EDIT_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadDeviceList());
  } catch (err) {
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().device;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudDevice'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allDevice = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/devices/all-device`;

  try {
    axios.get(path)
      .then((res) => {
        const { all_device } = res.data;
        dispatch({
          type: SET_ALL_DEVICE,
          all_device,
        });
      }).catch((err) => {
        dispatch({
          type: DEVICE_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
