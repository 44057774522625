import {
  LOAD_DEVICE_LIST, LOAD_DEVICE, ADD_DEVICE, EDIT_DEVICE, DEVICE_ERROR, MODIFY_MODAL, CLEAR, SET_ALL_DEVICE,
} from '../actions/deviceActions';

const initialState = {
  order: 'asc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  defaultItem: {
    type: 'Teherautó',
    status: 'ACTIVE',
  },
  modal: false,
  filters: {},
  defaultFilters: {
    type: '__ALL__',
    status: '__ALL__',
  },
  operations: ['edit'],
  headrows: [
    {
      id: 'identity',
      type: 'value',
      label: 'Azonosító',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'name',
      type: 'value',
      label: 'Eszköz neve',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'type',
      type: 'value',
      label: 'Eszköz típusa',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'status',
      type: 'value',
      label: 'Státusz',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_DEVICE_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case LOAD_DEVICE: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case ADD_DEVICE: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_DEVICE: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case DEVICE_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    case MODIFY_MODAL: {
      const { modal } = action;
      return {
        ...state,
        modal,
      };
    }
    case CLEAR:
      return {
        ...state,
        item: {},
        error: {},
      };
    case SET_ALL_DEVICE: {
      const { all_device } = action;
      return {
        ...state,
        all_device,
      };
    }
    default:
      return state;
  }
};
