import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { forgot } from '../../../../redux/actions/authActions';
import RenderField from '../../../../shared/components/form/RenderField';

class ForgotForm extends PureComponent {
  render() {
    const {
      handleSubmit, isAuthenticated, forgotten, t,
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit(forgotten)}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('forms.form_forgot.email')}</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={RenderField}
              type="text"
            />
          </div>
        </div>
        <div className="account__btns">
          <Link className="btn btn-secondary account__btn" to="/login">{t('forms.form_forgot.back')}</Link>
          <button className="btn btn-primary account__btn" type="submit">{t('forms.form_forgot.submit')}</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ForgotForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  forgotten: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { forgotten: forgot })(reduxForm({
  form: 'forgot_form',
})(withTranslation('common')(ForgotForm)));
