import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '../../../shared/components/Alert';

const Notices = class Notices extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  setImage = (value) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState(() => ({ src: value }));
  }

  render() {
    const { notices } = this.props;

    return (
      <div className="camera-block">
        <h3 className="bold-text">Értesítések</h3>
        {notices.map((notice) => (
          <Alert
            key={notice.id}
            color="danger"
            message={`${notice.name}: ${notice.description}`}
          />
        ))}
      </div>
    );
  }
};

Notices.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.shape({})),
};

Notices.defaultProps = {
  notices: [],
};

const mapStateToProps = (state) => ({
  notices: state.track.item.notices,
});

export default connect(mapStateToProps)(Notices);
