/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import {
  compose, withProps, withState, withHandlers,
} from 'recompose';
import {
  GoogleMap, withGoogleMap, withScriptjs,
} from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import DeviceInfoBox from './DeviceInfoBox';

const MarkerType1 = `${process.env.PUBLIC_URL}/img/map/marker1.svg`;
const MarkerType2 = `${process.env.PUBLIC_URL}/img/map/marker2.svg`;
const MarkerType2Active = `${process.env.PUBLIC_URL}/img/map/marker2active.svg`;

const loadMarkerIcon = (marker, selected) => {
  let icon = '';
  switch (marker.services) {
    case 'Logistics':
      return (
        (selected && selected.id === marker.id)
          ? MarkerType2Active
          : MarkerType2
      );
    default:
      icon = MarkerType1;
      break;
  }

  return icon;
};

const MarkerLabelStyling = {
  width: '124px',
  height: '40px',
  background: '#5F5F5F',
  borderRadius: '6px',
};

const Map = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD8wojoZeLwzxaWy_ekFiSfp18vhMYSsAQ',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '100vh' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withState('selected', 'setSelected', null),
  withState('infoOpen', 'setInfoOpen', false),
  withHandlers({
    markerClickHandler: ({ setSelected, setInfoOpen, infoOpen }) => (e, marker) => {
      setSelected(marker);

      if (infoOpen) {
        setInfoOpen(false);
      }

      setInfoOpen(true);
    },
    mapClickHandler: ({ setSelected, setInfoOpen }) => () => {
      setSelected(null);
      setInfoOpen(false);
    },
  }),
  withScriptjs,
  withGoogleMap,
)((props) => (
  <>
    {props.infoOpen && props.selected && (<DeviceInfoBox device={props.selected} />)}
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 47.505800, lng: 19.045052 }}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
      }}
      onClick={props.mapClickHandler}
    >
      <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={60}
      >
        {props.isMarkerShown
          && props.markers.devices !== null
          && props.markers.devices.map((marker) => {
            useEffect(() => {
              if (props.selected && marker.id === props.selected.id) {
                props.setSelected(marker);
              }
            }, [marker]);
            return (
              <MarkerWithLabel
                key={marker.id}
                position={marker.position}
                labelAnchor={new google.maps.Point(62, -25)}
                labelStyle={MarkerLabelStyling}
                icon={{
                  url: loadMarkerIcon(marker, props.selected),
                  scaledSize: new google.maps.Size(74, 74),
                  anchor: new google.maps.Point(37, 37),
                }}
                onClick={(e) => props.markerClickHandler(e, marker)}
              >
                <div className="marker-label">
                  <span>{marker.licencePlateNumber}</span>
                </div>
              </MarkerWithLabel>
            );
          })}
      </MarkerClusterer>
    </GoogleMap>
  </>
));

function BasicMap(devices) {
  return (
    <Col xs={12} md={12} lg={12}>
      <Map isMarkerShown markers={devices} />
    </Col>
  );
}

export default BasicMap;
