import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm, FormSection } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import HeadOffice from './HeadOffice';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import {
  loadDefault, addPartner, editPartner, modifyModal,
} from '../../../redux/actions/partnerActions';
import { defaultStatuses } from '../../../shared/utility/baseData';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDPartners = class CRUDPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));

    const { dispatch, itemId } = this.props;
    if (itemId === '') {
      dispatch(loadDefault());
    }
    dispatch(modifyModal());
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit,
    } = this.props;

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {itemId === '' ? 'Új partner' : `Partner szerkesztése #${itemId}`}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={handleSubmit(itemId === '' ? add : edit)} noValidate>
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Státusz</span>
                      <div className="form__form-group-field">
                        <Field
                          name="status"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={defaultStatuses}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Partner neve</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Cég neve</span>
                      <div className="form__form-group-field">
                        <Field
                          name="company"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Adószám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="tax_number"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Bankszámlaszám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="bank_account_number"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <FormSection name="headOffice">
                  <HeadOffice />
                </FormSection>

                <h6 className="modal-sub-title">KAPCSOLATTARTÁS</h6>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Kapcsolattartó</span>
                      <div className="form__form-group-field">
                        <Field
                          name="contact_name"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Kapcsolattartó Telefonszáma</span>
                      <div className="form__form-group-field">
                        <Field
                          name="contact_phone"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Kapcsolattartó Email címe</span>
                      <div className="form__form-group-field">
                        <Field
                          name="contact_email"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Megjegyzés, jegyzet</span>
                      <div className="form__form-group-field">
                        <Field
                          name="comment"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDPartners.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
};

CRUDPartners.defaultProps = {
  itemId: '',
};

const mapStateToProps = (state) => ({
  initialValues: state.partners.item,
  modal: state.partners.modal,
  itemId: state.partners.item.id,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudPartner',
  enableReinitialize: true,
})(CRUDPartners);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addPartner, edit: editPartner },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
