import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * primary, secondary, success, danger, warning, info, light, dark
 */

function AlertComponent(props) {
  const { color, message, visible } = props;

  return (
    <Alert color={color} isOpen={visible}>
      {message}
    </Alert>
  );
}

AlertComponent.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string,
  visible: PropTypes.bool,
};

AlertComponent.defaultProps = {
  color: 'info',
  message: '',
  visible: true,
};

export default AlertComponent;
