import React from 'react';
import SVG from 'react-inlinesvg';
import SidebarLink from './SidebarLink';
import LogoutLink from './LogoutLink';

const sidebarLogo = `${process.env.PUBLIC_URL}/img/SidebarLogo.svg`;
const dashboardIcon = `${process.env.PUBLIC_URL}/img/DashboardIcon.svg`;
const baseDataIcon = `${process.env.PUBLIC_URL}/img/BaseDataIcon.svg`;
const tasksIcon = `${process.env.PUBLIC_URL}/img/TasksIcon.svg`;
const logOutIcon = `${process.env.PUBLIC_URL}/img/LogOutIcon.svg`;

function SidebarContent() {
  return (
    <div className="sidebar__content">
      <div className="sidebar__logo">
        <SVG src={sidebarLogo} />
      </div>
      <ul className="sidebar__block">
        <SidebarLink
          title="Irányítópult"
          icon={dashboardIcon}
          route="/dashboard"
        />
        <SidebarLink
          title="Alap adatok"
          icon={baseDataIcon}
          route="/base"
        />
        <SidebarLink
          title="Feladatok"
          icon={tasksIcon}
          route="/service"
        />
      </ul>
      <ul className="sidebar__block_logout">
        <LogoutLink
          title="Kilépés"
          icon={logOutIcon}
          route="/logout"
        />
      </ul>
    </div>
  );
}

export default SidebarContent;
