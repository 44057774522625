import React from 'react';
import SVG from 'react-inlinesvg';
import ResetForm from './components/ResetForm';
import Alertv2 from '../../../shared/components/Alertv2';

const logo = `${process.env.PUBLIC_URL}/img/Logo.svg`;

function Reset() {
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <div className="account__logo">
              <SVG src={logo} />
            </div>
          </div>
          <Alertv2 />
          <ResetForm onSubmit />
        </div>
      </div>
    </div>
  );
}

export default Reset;
