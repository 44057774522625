/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderField from '../../../shared/components/form/RenderField';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import { deviceFilterStatuses, deviceFilterTypes } from '../../../shared/utility/baseData';

const DeviceFilter = class DeviceFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Col xs={12} md={2} lg={2} xl={2}>
        <Card>
          <CardBody>
            <div className="card-title">
              <h3 className="bold-text">szűrő</h3>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Eszköz neve</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={RenderField}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Eszköz típusa</span>
                <div className="form__form-group-field">
                  <Field
                    name="type"
                    component={renderSelectFieldwithSelected}
                    type="text"
                    options={deviceFilterTypes}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Státusz</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectFieldwithSelected}
                    type="text"
                    options={deviceFilterStatuses}
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button className="submit-button" type="submit">Szűrés</Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
};

DeviceFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: state.device.filters,
});

const FormDecoratedComponent = reduxForm({
  form: 'deviceFilter',
  enableReinitialize: true,
})(DeviceFilter);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
