import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_TASK_TYPE = 'LOAD_TASK_TYPE';
export const LOAD_TASK_TYPE_LIST = 'LOAD_TASK_TYPE_LIST';
export const ADD_TASK_TYPE = 'ADD_TASK_TYPE';
export const EDIT_TASK_TYPE = 'EDIT_TASK_TYPE';
export const TASK_TYPE_ERROR = 'TASK_TYPE_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_TASK_TYPES = 'SET_ALL_TASK_TYPES';

export const loadTaskType = (taskTypeId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/task-types/${taskTypeId}`;

  if (taskTypeId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const taskType = res.data;
          const item = taskType;
          item.unit = taskType.unit.id;

          dispatch({
            type: LOAD_TASK_TYPE,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: TASK_TYPE_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_TASK_TYPE,
      item: getState().taskType.defaultItem,
    });
  }
};

export const loadTaskTypeList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/task-types${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'name');
        dispatch({
          type: LOAD_TASK_TYPE_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: TASK_TYPE_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: TASK_TYPE_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addTaskType = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task-types`, body);
    res.data.unit = res.data.unit.id;

    dispatch({
      type: ADD_TASK_TYPE,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadTaskTypeList());
  } catch (err) {
    dispatch({
      type: TASK_TYPE_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editTaskType = (taskType) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/task-types/${taskType.id}`;
  const body = JSON.stringify(taskType);

  try {
    const res = await axios.put(newUrl, body);
    res.data.unit = res.data.unit.id;

    dispatch({
      type: EDIT_TASK_TYPE,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadTaskTypeList());
  } catch (err) {
    dispatch({
      type: TASK_TYPE_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().taskType;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudTaskType'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allTaskTypes = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/task-types/all-task-types`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_task_types } = res.data;

        dispatch({
          type: SET_ALL_TASK_TYPES,
          all_task_types,
        });
      }).catch((err) => {
        dispatch({
          type: TASK_TYPE_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: TASK_TYPE_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
