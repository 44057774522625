import React from 'react';
import {
  Container, Col, Row, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import interaction from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { editSubTask } from '../../redux/actions/subTaskActions';

class Organizes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      colors: [
        '#008000', '#FFA500', '#D94343', '#0A8DD7', '#9528EB',
        // '#5FCE5F', '#FFC761', '#DB8E8E', '#97D2F2', '#C08EE7',
      ],
      resources: [],
      events: [],
    };
  }

  getTaskDate = (info) => {
    const { dispatch } = this.props;
    const subTask = {};

    subTask.id = parseInt(info.event.id, 10);
    // eslint-disable-next-line no-underscore-dangle
    subTask.account_id = parseInt(info.event.getResources()[0]._resource.id, 10);

    subTask.date_task = info.event.start
      .toLocaleDateString('hu-HU', { year: 'numeric', month: 'numeric', day: 'numeric' })
      .replace(/\./g, '')
      .replace(/ /g, '-');
    subTask.time_from = info.event.start
      .toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    subTask.date_task_end = info.event.end
      .toLocaleDateString('hu-HU', { year: 'numeric', month: 'numeric', day: 'numeric' })
      .replace(/\./g, '')
      .replace(/ /g, '-');
    subTask.time_to = info.event.end
      .toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

    dispatch(editSubTask(subTask));
  }

  setNewStartDate = (startStr) => {
    let newStartDate = new Date(startStr).toLocaleDateString('hu-HU', { year: 'numeric', month: '2-digit' })
      .replace(/\./g, '')
      .replace(/ /g, '-');

    newStartDate += '-01';

    return newStartDate;
  }

  setNewEndDate = (endStr) => {
    const endInfo = new Date(endStr);
    const endInfoAddMonth = endInfo.setMonth(endInfo.getMonth() + 1);

    let newEndDate = new Date(endInfoAddMonth)
      .toLocaleDateString('hu-HU', { year: 'numeric', month: '2-digit' })
      .replace(/\./g, '')
      .replace(/ /g, '-');

    newEndDate += '-01';

    return newEndDate;
  }

  setResources = (events) => {
    const { colors } = this.state;
    const newResources = [];
    let c = 0;

    for (let i = 0; i < events.length; i += 1) {
      if (newResources.filter((nr) => nr.id === events[i].account_id).length === 0) {
        const resource = {
          id: events[i].account_id,
          title: events[i].account,
          eventColor: colors[c],
        };

        c += 1;
        if (c === colors.length) {
          c = 0;
        }

        newResources.push(resource);
      }
    }

    return newResources;
  }

  getCalendarData = async (fetchInfo, successCallback) => {
    const { startDate, endDate, events } = this.state;
    let newEvents = events;

    const newStartDate = this.setNewStartDate(fetchInfo.startStr);
    const newEndDate = this.setNewEndDate(fetchInfo.endStr);

    if (startDate !== newStartDate || endDate !== newEndDate) {
      const path = `${process.env.REACT_APP_API_URL}/api/sub-tasks?order=asc&orderBy=account&
        f-date_task=${newStartDate}&f-date_task_end=${newEndDate}`;

      const res = await axios.get(path);
      newEvents = res.data.list;

      const newResources = this.setResources(newEvents);

      this.setState({
        startDate: newStartDate,
        endDate: newEndDate,
        events: newEvents,
        resources: newResources,
      });
    }

    successCallback(
      newEvents.map((event) => ({
        id: event.id,
        resourceId: event.account_id,
        start: `${event.date_task}T${event.time_from}`,
        title: `#${event.id} ${event.task_type} - ${event.address_task}`,
        end: `${event.date_task_end}T${event.time_to}`,
      })),
    );
  }

  handleEventPositioned = (info) => {
    const dataFor = `tt-${info.event.id}`;
    info.el.setAttribute('data-tip', 'some text tip');
    info.el.setAttribute('data-for', dataFor);
    ReactTooltip.rebuild();
  }

  render() {
    const { resources, events } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12} className="container-list">
            <Card>
              <CardBody>
                <FullCalendar
                  schedulerLicenseKey="0508353978-fcs-1588673081"
                  height={800}
                  locale={huLocale}
                  aspectRatio={1.5}
                  local="ISO"
                  defaultDate={new Date().toJSON().slice(0, 10)}
                  editable
                  droppable={false}
                  slotMinutes={30}
                  snapDuration="00:30:00"
                  timeFormat="H:mm"
                  minTime="07:00:00"
                  maxTime="18:00:00"
                  slotDuration="00:30:00"
                  defaultView="resourceTimelineWeek"
                  header={{
                    left: 'today prev,next',
                    center: 'title',
                    right: 'resourceTimelineMonth, resourceTimelineWeek, resourceTimelineDay',
                  }}
                  plugins={[resourceTimelinePlugin, interaction]}
                  resourceAreaWidth={250}
                  resources={resources}
                  resourceLabelText="Munkatársak"
                  eventResize={(info) => this.getTaskDate(info)}
                  eventDrop={(info) => this.getTaskDate(info)}
                  drop={(info) => this.getTaskDate(info)}
                  events={
                    (fetchInfo, successCallback) => this.getCalendarData(fetchInfo, successCallback)
                  }
                  eventPositioned={(info) => this.handleEventPositioned(info)}
                />

                {events.map((event) => (
                  <ReactTooltip
                    id={`tt-${event.id}`}
                    aria-haspopup="true"
                    key={`key-${event.id}`}
                    className="custom-tooltip"
                  >
                    <p>#{event.id} Részfeladat</p>
                    <ul>
                      <li>Részfeladat: {event.task_type}</li>
                      <li>Végzi: {event.account} </li>
                      <li>Helyszín: {event.address_task}</li>
                      <li>Célállomás: {event.address_target}</li>
                    </ul>
                  </ReactTooltip>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Organizes.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Organizes);
