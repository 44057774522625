import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NavTab } from 'react-router-tabs';
import PrivateRoute from '../../shared/components/PrivateRoute';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

import Login from '../Accounts/Login/index';
import Forgot from '../Accounts/Forgot/index';
import Reset from '../Accounts/Reset/index';

import GoogleMap from '../Maps/index';
import Partners from '../Partners/index';
import Address from '../Address/index';
import Contracts from '../Contracts/index';
import Accounts from '../Accounts/index';
import Devices from '../Devices/index';
import Materials from '../Materials/index';
import TaskType from '../TaskType/index';

import Tasks from '../Tasks/index';
import SubTasks from '../SubTasks/index';
import Tracks from '../Tracks/index';
import Organizes from '../Organizes/index';

function Service() {
  return (
    <div>
      <div className="navigation-container">
        <div className="navigation">
          <div className="left-navigation">
            <NavTab to="/service/tasks">Feladatok</NavTab>
            <NavTab to="/service/sub-tasks">Részfeladatok</NavTab>
            <NavTab to="/service/tracks">Követés</NavTab>
            <NavTab to="/service/organizes">Beosztás</NavTab>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path="/service"
          render={() => <Redirect replace to="/service/tasks" />}
        />
        <Route path="/service/tasks" component={Tasks} />
        <Route path="/service/sub-tasks" component={SubTasks} />
        <Route path="/service/tracks" component={Tracks} />
        <Route path="/service/organizes" component={Organizes} />
      </Switch>
    </div>
  );
}

function Base() {
  return (
    <div>
      <div className="navigation-container">
        <div className="navigation">
          <div className="left-navigation">
            <NavTab to="/base/partners">Partnerek</NavTab>
            <NavTab to="/base/address">Címek</NavTab>
            <NavTab to="/base/contracts">Szerződések</NavTab>
            <NavTab to="/base/accounts">Munkatársak</NavTab>
            <NavTab to="/base/devices">Eszközök</NavTab>
            <NavTab to="/base/materials">Anyagok</NavTab>
            <NavTab to="/base/task-type">Feladattípusok</NavTab>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path="/base"
          render={() => <Redirect replace to="/base/partners" />}
        />
        <Route path="/base/partners" component={Partners} />
        <Route path="/base/address" component={Address} />
        <Route path="/base/contracts" component={Contracts} />
        <Route path="/base/accounts" component={Accounts} />
        <Route path="/base/devices" component={Devices} />
        <Route path="/base/materials" component={Materials} />
        <Route path="/base/task-type" component={TaskType} />
      </Switch>
    </div>
  );
}

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route
        exact
        path="/"
        render={() => <Redirect replace to="/dashboard" />}
      />
      <Route path="/dashboard" component={GoogleMap} />
      <Route path="/service" component={Service} />
      <Route path="/base" component={Base} />
    </div>
  </div>
);

function Router() {
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/reset" component={Reset} />
          <PrivateRoute path="/" component={wrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
}

export default Router;
