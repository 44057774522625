import React, { PureComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import hu from 'date-fns/locale/hu';

registerLocale('hu', hu);

class DatePickerField extends PureComponent {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { onChange } = this.props;

    if (date !== null) {
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      const dateString = `${ye}-${mo}-${da}`;
      onChange(dateString);
    } else {
      onChange(date);
    }
  }

  render() {
    const {
      value, name,
    } = this.props;

    return (
      <div className="date-picker">
        <DatePicker
          locale="hu"
          name={name}
          className="form__form-group-datepicker"
          selected={(value === '' || value === '1970-01-01') ? null : new Date(value)}
          onChange={this.handleChange}
          dateFormat="yyyy-MM-dd"
          dropDownMode="select"
          isClearable
          autoComplete="off"
        />
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input, meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <DatePickerField {...input} />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
};

DatePickerField.defaultProps = {
  value: null,
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderDatePickerField.defaultProps = {
  meta: null,
};

export default renderDatePickerField;
