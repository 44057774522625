import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const errorTranslate = (msg, t) => {
  const msgTranslated = t(msg);
  return msgTranslated;
};

const disableCharacters = (e, type) => {
  if (type === 'number' && (e.key === 'e' || e.keyCode === 69 || e.keyCode === 190)) {
    e.preventDefault();
  }
};

function RenderField({
  t, input, placeholder, type, meta: { touched, error },
}) {
  return (
    <div className="form__form-group-input-wrap">
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        autoComplete="off"
        onKeyDown={(e) => disableCharacters(e, type)}
      />
      {touched && error && <span className="form__form-group-error">{errorTranslate(error, t)}</span>}
    </div>
  );
}

RenderField.propTypes = {
  t: PropTypes.func.isRequired,
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

export default withTranslation('common')(RenderField);
