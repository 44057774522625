import React from 'react';
import {
  Container, Col, Row, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadTaskTypeList, loadTaskType, modifyModal,
} from '../../redux/actions/taskTypeActions';
import { clearAlert } from '../../redux/actions/alertActions';
import { allUnit } from '../../redux/actions/unitActions';
import DynamicTable from '../../shared/components/dynamicTable/DynamicTable';
import TaskTypeFilter from './components/TaskTypeFilter';
import CRUDTaskType from './components/CRUDTaskType';
import { deleteFilterFromUrl, setFilterToUrl, setUrlQuery } from '../../shared/utility/urlModify';

class TaskType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };
    const { dispatch } = this.props;
    dispatch(clearAlert());
    dispatch(loadTaskTypeList());
  }

  updateList = () => {
    const { dispatch } = this.props;
    dispatch(loadTaskTypeList());
  }

  setFilter = (post) => {
    deleteFilterFromUrl();
    setFilterToUrl(post);
    setUrlQuery('page', 0);
    this.updateList();
  }

  load = (id) => {
    const { dispatch } = this.props;

    dispatch(allUnit());
    dispatch(loadTaskType(id));
    dispatch(modifyModal());

    this.setState(() => ({
      itemId: id,
    }));
  }

  render() {
    const {
      list, order, headrows, operations, orderBy, totalCount, rowsPerPage, page,
    } = this.props;

    const { itemId } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={10} lg={10} xl={10} className="container-list">
            <Card>
              <CardBody>
                <CRUDTaskType itemId={itemId} onSubmit={this.save} />
                <DynamicTable
                  headrows={headrows}
                  operations={operations}
                  data={list}
                  showCheckBox={false}
                  order={order}
                  orderBy={orderBy}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  ref={this.tableChildUpdate}
                  updateList={this.updateList}
                  edit={this.load}
                />
              </CardBody>
            </Card>
          </Col>
          <TaskTypeFilter onSubmit={this.setFilter} />
        </Row>
      </Container>
    );
  }
}

TaskType.propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  list: state.taskType.data,
  order: state.taskType.order,
  orderBy: state.taskType.orderBy,
  headrows: state.taskType.headrows,
  operations: state.taskType.operations,
  totalCount: state.taskType.totalCount,
  rowsPerPage: state.taskType.rowsPerPage,
  page: state.taskType.page,
});

export default connect(mapStateToProps)(TaskType);
