import queryString from 'query-string';
import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl } from '../../shared/utility/urlModify';

export const LOAD_DEFAULT = 'LOAD_DEFAULT';
export const LOAD_PARTNER = 'LOAD_PARTNER';
export const LOAD_PARTNER_LIST = 'LOAD_PARTNER_LIST';
export const ADD_PARTNER = 'ADD_PARTNER';
export const EDIT_PARTNER = 'EDIT_PARTNER';
export const PARTNER_ERROR = 'PARTNER_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_PARTNERS = 'SET_ALL_PARTNERS';

const defaultItem = {
  headOffice: {
    country: 'Magyarország',
    type: 'HEAD_OFFICE',
    status: 'ACTIVE',
  },
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const loadDefault = () => (dispatch) => {
  dispatch({
    type: LOAD_DEFAULT,
    item: defaultItem,
  });
};

export const loadPartner = (partnerId) => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/partners/${partnerId}`;

  try {
    axios.get(path)
      .then((res) => {
        const partner = res.data;
        const allowed = ['HEAD_OFFICE'];
        const filtered = partner.addresses.filter((item) => allowed.includes(item.type));
        const clone = { ...defaultItem };
        const temp = {
          headOffice: filtered[0],
        };
        const item = Object.assign(clone, temp, partner);
        dispatch({
          type: LOAD_PARTNER,
          item,
        });
      });
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const loadPartnerList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/partners${window.location.search}`;

  const defaultFilters = {
    name: '',
    contact_email: '',
    company: '',
    tax_number: '',
    contact_name: '',
    contact_phone: '',
  };

  const filtersFromUrl = getFilterFromUrl();
  const filters = Object.assign(defaultFilters, filtersFromUrl);

  try {
    axios.get(path)
      .then((res) => {
        const payload = {};
        const query = queryString.parse(window.location.search);
        const { totalCount, list } = res.data;
        const page = typeof query.page !== 'undefined' ? query.page : 0;
        const rpp = typeof query.rowsPerPage !== 'undefined' ? query.rowsPerPage : 10;

        payload.totalCount = totalCount;
        payload.data = list;
        payload.order = typeof query.order !== 'undefined' ? query.order : 'asc';
        payload.orderBy = typeof query.orderBy !== 'undefined' ? query.orderBy : 'id';
        payload.rowsPerPage = parseInt(rpp, 10);
        payload.page = parseInt(page, 10);

        dispatch({
          type: LOAD_PARTNER_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: PARTNER_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addPartner = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/partners`, body);

    dispatch({
      type: ADD_PARTNER,
      payload: res.data,
    });
    dispatch(loadPartner(res.data.id));
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadPartnerList());
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data, true);
  }
};

export const editPartner = (partner) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/partners/${partner.id}`;
  const body = JSON.stringify(partner);

  try {
    const res = await axios.put(newUrl, body);

    dispatch({
      type: EDIT_PARTNER,
      payload: res.data,
    });
    dispatch(loadPartner(res.data.id));
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadPartnerList());
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data, true);
  }
};

export const modifyModal = () => async (dispatch, getState) => {
  dispatch({
    type: MODIFY_MODAL,
    modal: !getState().partners.modal,
  });

  if (getState().partners.modal === false) {
    dispatch(clear());
    dispatch(reset('crudPartner'));
  }
};

export const allPartners = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/partners/all-partners`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_partners } = res.data;
        dispatch({
          type: SET_ALL_PARTNERS,
          all_partners,
        });
      }).catch((err) => {
        dispatch({
          type: PARTNER_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
