import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import RenderTextarea from '../../../shared/components/form/RenderTextarea';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import renderMultiSelectField from '../../../shared/components/form/RenderMultiSelect';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import FileList from '../../../shared/components/form/FileList';
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import { allPartners } from '../../../redux/actions/partnerActions';
import { deteteFile } from '../../../redux/actions/fileActions';
import {
  setTaskFiles, addTask, editTask, modifyModal, loadTask,
} from '../../../redux/actions/taskActions';
import { allContracts } from '../../../redux/actions/contractActions';
import { allAccounts } from '../../../redux/actions/accountsActions';
import { allAddresses } from '../../../redux/actions/addressActions';
import { allMaterials } from '../../../redux/actions/materialActions';
import { allTaskTypes } from '../../../redux/actions/taskTypeActions';
import { priorityTypes, taskStatuses } from '../../../shared/utility/baseData';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDTask = class CRUDTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(allContracts());
    dispatch(allAccounts());
    dispatch(allAddresses());
    dispatch(allMaterials());
    dispatch(allTaskTypes());
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadTask());
    }

    dispatch(allPartners());
    dispatch(modifyModal());
  }

  deleteFile = (id) => {
    const { itemId } = this.state;
    const { dispatch } = this.props;
    dispatch(deteteFile(id));
    dispatch(setTaskFiles(itemId));
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit, fileList, contracts, colleagues, addresses, materials, taskTypes,
    } = this.props;

    let modalTitle = 'Új feladat';
    if (itemId !== '') { modalTitle = `Feladat szerkesztése #${itemId}`; }

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {modalTitle}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form
              className="form"
              onSubmit={handleSubmit(itemId === '' ? add : edit)}
              noValidate
              encType="multipart/form-data"
            >
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Szerződés</span>
                      <div className="form__form-group-field">
                        <Field
                          name="contract_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={contracts}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Státusz</span>
                      <div className="form__form-group-field">
                        <Field
                          name="status"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={taskStatuses}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Prioritás</span>
                      <div className="form__form-group-field">
                        <Field
                          name="priority"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={priorityTypes}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Művezető</span>
                      <div className="form__form-group-field">
                        <Field
                          name="account_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={colleagues}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat kezdete</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_from"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat vége</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_to"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat helyszíne</span>
                      <div className="form__form-group-field">
                        <Field
                          name="address_id_task"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={addresses}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Célállomás</span>
                      <div className="form__form-group-field">
                        <Field
                          name="address_id_target"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={addresses}
                          defaultValue={{ value: '', label: 'Nem adok meg értéket' }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Feladat típusa</span>
                      <div className="form__form-group-field">
                        <Field
                          name="task_type_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={taskTypes}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Anyag típusa</span>
                      <div className="form__form-group-field">
                        <Field
                          name="material_id"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={materials}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Mennyiség</span>
                      <div className="form__form-group-field">
                        <Field
                          name="quantity"
                          component={RenderField}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Anyag díj</span>
                      <div className="form__form-group-field">
                        <Field
                          name="material_price"
                          component={RenderField}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Munka díj</span>
                      <div className="form__form-group-field">
                        <Field
                          name="work_price"
                          component={RenderField}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Részletes leírás</span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          component={RenderTextarea}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Munkatárs(ak)</span>
                      <div className="form__form-group-field">
                        <Field
                          name="account_ids"
                          component={renderMultiSelectField}
                          type="text"
                          options={colleagues}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Dokumentum feltöltése (excel, word, pdf)</span>
                      <div className="form__form-group-field">
                        <Field
                          name="files"
                          component={renderDropZoneMultipleField}
                          type="file"
                          accept="application/pdf, application/msword, .doc, .docx, .xlsx, .xls"
                        />
                      </div>
                    </div>
                  </Col>

                  {fileList.length > 0 && (
                    <Col md={6} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Feltöltött fájlok</span>
                        <FileList files={fileList} deleteFile={this.deleteFile} />
                      </div>
                    </Col>
                  )}
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDTask.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  colleagues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  addresses: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  materials: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  taskTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
};

CRUDTask.defaultProps = {
  itemId: '',
  contracts: [],
  fileList: [],
  colleagues: [],
  addresses: [],
  materials: [],
  taskTypes: [],
};

const mapStateToProps = (state) => ({
  initialValues: state.task.item,
  modal: state.task.modal,
  itemId: state.task.item.id,
  parentId: state.task.item.parent_id,
  contracts: state.contract.all_contracts,
  colleagues: state.accounts.all_accounts,
  addresses: state.address.all_addresses,
  materials: state.material.all_materials,
  taskTypes: state.taskType.all_task_types,
  fileList: state.task.item.fileList,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudTask',
  enableReinitialize: true,
})(CRUDTask);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addTask, edit: editTask },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
