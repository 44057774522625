import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';
import BeforeValue from './BeforeValue';

const addSubListItemIcon = `${process.env.PUBLIC_URL}/img/AddSubListItemIcon.svg`;
const editIcon = `${process.env.PUBLIC_URL}/img/EditIcon.svg`;
const openListIcon = `${process.env.PUBLIC_URL}/img/OpenListIcon.svg`;
const closeListIcon = `${process.env.PUBLIC_URL}/img/CloseListIcon.svg`;
const excelIcon = `${process.env.PUBLIC_URL}/img/ExcelIcon.svg`;
const TrackIcon = `${process.env.PUBLIC_URL}/img/TrackIcon.svg`;

class RowContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  track = (id) => {
    const { setActiveRow, trackRecord } = this.props;
    setActiveRow(id, false);
    trackRecord(id);
  }

  render() {
    const {
      row, headrows, operations, edit, setActiveRow, activeRow, exportDocumentum, addSubListItem,
    } = this.props;

    let rowClassName = 'table-row';
    if (row.id === activeRow) {
      rowClassName += ' active';
    }
    if (typeof row.row_color !== 'undefined') {
      rowClassName += ` ${row.row_color}`;
    }
    return (
      <TableRow className={rowClassName} key={row.id}>
        {headrows.map((cell) => (
          <TableCell key={row.id + cell.id} className={`cell-content-text ${cell.class}`} align="left">
            {cell.valueBefore !== '' && (
              <BeforeValue type={cell.valueBefore} value={row[cell.id]} />)}
            {<div dangerouslySetInnerHTML={{ __html: row[cell.id] }} />}
            {cell.valueAfter}
          </TableCell>
        ))}

        {typeof operations !== 'undefined' && (
        <TableCell key={`${row.id}_edit`} className="cell-content-operations" align="left">

          {operations.includes('edit') && (
          <button value={row.id} onClick={() => edit(row.id)} type="button" data-tip data-for="edit-tt">
            <SVG src={editIcon} />
            <ReactTooltip id="edit-tt" aria-haspopup="true" className="custom-tooltip">
              <p>Szerkesztés</p>
            </ReactTooltip>
          </button>
          )}

          {operations.includes('excelExport') && (
          <button value={row.id} onClick={() => exportDocumentum(row.id)} type="button" data-tip data-for="excel-tt">
            <SVG src={excelIcon} />
            <ReactTooltip id="excel-tt" aria-haspopup="true" className="custom-tooltip">
              <p>Excel exportálása</p>
            </ReactTooltip>
          </button>
          )}

          {operations.includes('add') && (
          <button value={row.id} onClick={() => addSubListItem(row.id)} type="button" data-tip data-for="add-tt">
            <SVG src={addSubListItemIcon} />
            <ReactTooltip id="add-tt" aria-haspopup="true" className="custom-tooltip">
              <p>Al-listához hozzáadás</p>
            </ReactTooltip>
          </button>
          )}

          {operations.includes('toggle') && (
          <button value={row.id} onClick={() => setActiveRow(row.id, true)} type="button" data-tip data-for="open-tt">
            {row.id === activeRow ? <SVG src={openListIcon} /> : <SVG src={closeListIcon} /> }
            <ReactTooltip id="open-tt" aria-haspopup="true" className="custom-tooltip">
              <p>Al-lista megtekintése</p>
            </ReactTooltip>
          </button>
          )}

          {operations.includes('track') && (
          <button value={row.id} onClick={() => this.track(row.id)} type="button" data-tip data-for="track-tt">
            {row.id === activeRow ? <SVG src={TrackIcon} className="track-active" /> : <SVG src={TrackIcon} /> }
            <ReactTooltip id="track-tt" aria-haspopup="true" className="custom-tooltip">
              <p>Kiválasztás</p>
            </ReactTooltip>
          </button>
          )}
        </TableCell>
        )}
      </TableRow>
    );
  }
}

RowContent.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    row_color: PropTypes.string,
  }).isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  edit: PropTypes.func.isRequired,
  exportDocumentum: PropTypes.func.isRequired,
  trackRecord: PropTypes.func.isRequired,
  addSubListItem: PropTypes.func.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  activeRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RowContent;
