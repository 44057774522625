import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

class ColleagueSubTaskList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { list, unit } = this.props;

    return (
      <>
        {list.length === 0 && (
        <div className="empty-content"> Nincs megoldott részfeladat.
        </div>
        )}

        {list.length > 0 && (
        <div className="material-table__wrap">
          <Table className="material-table">
            <TableHead>
              <TableRow className="table-row">
                <TableCell
                  key="id_head"
                  align="left"
                >
                  Munkatárs
                </TableCell>
                <TableCell
                  key="company"
                  align="left"
                >
                  Műszak vezető
                </TableCell>
                <TableCell
                  key="subject"
                  align="left"
                >
                  Feladat
                </TableCell>
                <TableCell
                  key="date_from"
                  align="left"
                >
                  Teljesítve
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow
                  className="table-row"
                  key={row.id}
                >
                  <TableCell key={`${row.id}_colleague`} className="cell-content-text" align="left">
                    <div>{row.colleague}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_shift_leader`} className="cell-content-text" align="left">
                    <div>{row.shift_leader}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_task_type`} className="cell-content-text" align="left">
                    <div>{row.task_type}</div>
                  </TableCell>
                  <TableCell key={`${row.id}_sum`} className="cell-content-text" align="left">
                    <div dangerouslySetInnerHTML={{ __html: `${row.sum} ${unit}` }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        )}
      </>
    );
  }
}

ColleagueSubTaskList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unit: PropTypes.string.isRequired,
};

export default ColleagueSubTaskList;
