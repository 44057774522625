import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import {
  addAddress, editAddress, modifyModal, loadAddress,
} from '../../../redux/actions/addressActions';
import { defaultStatuses, countries, addressTypes } from '../../../shared/utility/baseData';
import { allPartners } from '../../../redux/actions/partnerActions';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDAddress = class CRUDAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadAddress());
    }

    dispatch(allPartners());
    dispatch(modifyModal());
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit, companies, showType,
    } = this.props;

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {itemId === '' ? 'Új cím' : `Cím szerkesztése #${itemId}`}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={handleSubmit(itemId === '' ? add : edit)} noValidate>
              <Container>
                <Alertv2 />

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Státusz</span>
                      <div className="form__form-group-field">
                        <Field
                          name="status"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={defaultStatuses}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Cégnév</span>
                      <div className="form__form-group-field">
                        <Field
                          name="partner"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={companies}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {showType && (
                  <Row>
                    <Col xs={12} sm={6} md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Cím típusa</span>
                        <div className="form__form-group-select">
                          <Field
                            name="type"
                            component={renderSelectFieldwithSelected}
                            type="text"
                            options={addressTypes}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={12} sm={6} md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ország</span>
                      <div className="form__form-group-select">
                        <Field
                          name="country"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={countries}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Irányítószám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="zip_code"
                          component={RenderField}
                          type="number"
                          parse={(value) => Number(value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Város</span>
                      <div className="form__form-group-field">
                        <Field
                          name="city"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Közterület, házszám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="street"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Telefonszám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="phone_number"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDAddress.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showType: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
};

CRUDAddress.defaultProps = {
  itemId: '',
  companies: [],
};

const mapStateToProps = (state) => ({
  initialValues: state.address.item,
  modal: state.address.modal,
  itemId: state.address.item.id,
  showType: state.address.item.type !== 'HEAD_OFFICE',
  companies: state.partners.all_partners,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudAddress',
  enableReinitialize: true,
})(CRUDAddress);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addAddress, edit: editAddress },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
