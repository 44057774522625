import { SubmissionError } from 'redux-form';

const throwError = (errors, extra = false) => {
  const errorObject = {};

  if (extra === true) {
    const { main, extension } = errors;
    if (main && extension) {
      main.violations.map((violation) => {
        errorObject[violation.propertyPath] = violation.title;
        return errorObject;
      });

      const headOffice = {};

      extension.violations.map((violation) => {
        headOffice[violation.propertyPath] = violation.title;
        const temp = { headOffice };
        Object.assign(errorObject, temp);
        return errorObject;
      });
    } else {
      // eslint-disable-next-line no-underscore-dangle
      errorObject._error = errors.detail;
    }
  } else {
    const { violations } = errors;
    if (violations) {
      violations.map((violation) => {
        errorObject[violation.propertyPath] = violation.title;
        return errorObject;
      });
    } else {
      // eslint-disable-next-line no-underscore-dangle
      errorObject._error = errors.detail;
    }
  }

  throw new SubmissionError(errorObject);
};

export default throwError;
