import React from 'react';
import {
  Container, Col, Row, Card, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadTrackList, setTrackFiles, setTrackNotices,
} from '../../redux/actions/trackActions';
import DynamicTable from '../../shared/components/dynamicTable/DynamicTable';
import TrackTabs from './components/TrackTabs';
import { deleteFilterFromUrl, setFilterToUrl, setUrlQuery } from '../../shared/utility/urlModify';

class Trucks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };
    const { dispatch } = this.props;
    dispatch(loadTrackList());
  }

  updateList = () => {
    const { dispatch } = this.props;
    dispatch(loadTrackList());
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  setFilter = (post) => {
    deleteFilterFromUrl();
    setFilterToUrl(post);
    setUrlQuery('page', 0);
    this.updateList();
  }

  track = (id) => {
    const { itemId } = this.state;
    const { dispatch } = this.props;
    let newItemId = '';

    if (itemId !== id) {
      newItemId = id;
      dispatch(setTrackFiles(id));
      dispatch(setTrackNotices(id));
    }

    this.setState(() => ({
      itemId: newItemId,
    }));
  }

  render() {
    const {
      list, order, headrows, operations, orderBy, totalCount, rowsPerPage, page,
    } = this.props;

    const { itemId } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={8} lg={8} xl={8} className="container-list">
            <Card>
              <CardBody>
                <DynamicTable
                  headrows={headrows}
                  operations={operations}
                  data={list}
                  showCheckBox={false}
                  order={order}
                  orderBy={orderBy}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  ref={this.tableChildUpdate}
                  updateList={this.updateList}
                  trackRecord={this.track}
                />
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} md={4} lg={4} xl={4}>
            {itemId !== '' && (
            <TrackTabs itemId={itemId} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

Trucks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  list: state.track.data,
  order: state.track.order,
  orderBy: state.track.orderBy,
  headrows: state.track.headrows,
  operations: state.track.operations,
  totalCount: state.track.totalCount,
  rowsPerPage: state.track.rowsPerPage,
  page: state.track.page,
});

export default connect(mapStateToProps)(Trucks);
