import {
  SET_ALERT,
  REMOVE_ALERT,
  CLEAR_ALERT,
} from '../actions/alertActions';

const initialState = [];

export default (state = initialState, action = null) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      if (!(state.filter((alert) => alert.msg === payload.msg).length > 0)) {
        return [...state, payload];
      }
      return [...state];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    case CLEAR_ALERT:
      return [];
    default:
      return state;
  }
};
