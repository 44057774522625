import {
  LOAD_TRACK_LIST, TRACK_ERROR, SET_TRACK_FILES, SET_TRACK_NOTICES,
} from '../actions/trackActions';

const initialState = {
  order: 'asc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  filters: {},
  operations: ['track'],
  headrows: [
    {
      id: 'name',
      type: 'value',
      label: 'Munkatárs',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'task',
      type: 'value',
      label: 'Feladat',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'fulfillment',
      type: 'value',
      label: 'Elvégzett mennyiség',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'address_task',
      type: 'value',
      label: 'Helyszín',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'address_target',
      type: 'value',
      label: 'Célállomás',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
  message: {},
  dangerMessage: { text: 'Sikertelen mentés!', color: 'danger' },
  successMessage: { text: 'Sikeres mentés!', color: 'success' },
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_TRACK_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case SET_TRACK_FILES: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case SET_TRACK_NOTICES: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case TRACK_ERROR: {
      const { payload } = action;
      return {
        ...state,
        message: state.dangerMessage,
        error: {
          payload,
        },
      };
    }
    default:
      return state;
  }
};
