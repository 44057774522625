import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_MATERIAL = 'LOAD_MATERIAL';
export const LOAD_MATERIAL_LIST = 'LOAD_MATERIAL_LIST';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const EDIT_MATERIAL = 'EDIT_MATERIAL';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_MATERIALS = 'SET_ALL_MATERIALS';

export const loadMaterial = (materialId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/materials/${materialId}`;

  if (materialId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const item = res.data;

          dispatch({
            type: LOAD_MATERIAL,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: MATERIAL_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_MATERIAL,
      item: getState().material.defaultItem,
    });
  }
};

export const loadMaterialList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/materials${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'name');
        dispatch({
          type: LOAD_MATERIAL_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: MATERIAL_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: MATERIAL_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addMaterial = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/materials`, body);

    dispatch({
      type: ADD_MATERIAL,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadMaterialList());
  } catch (err) {
    dispatch({
      type: MATERIAL_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editMaterial = (material) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/materials/${material.id}`;
  const body = JSON.stringify(material);

  try {
    const res = await axios.put(newUrl, body);

    dispatch({
      type: EDIT_MATERIAL,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadMaterialList());
  } catch (err) {
    dispatch({
      type: MATERIAL_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().material;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudMaterial'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allMaterials = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/materials/all-materials`;

  try {
    axios.get(path)
      .then((res) => {
        const { all_materials } = res.data;

        dispatch({
          type: SET_ALL_MATERIALS,
          all_materials,
        });
      }).catch((err) => {
        dispatch({
          type: MATERIAL_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: MATERIAL_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
