import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class BeforeValue extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      /* type, */
      value,
    } = this.props;

    let color = 'green';

    switch (true) {
      case (value <= 20):
        color = 'red';
        break;
      case (value <= 70):
        color = 'orange';
        break;
      default:
        color = 'green';
    }

    const className = `point ${color}-point`;

    return (
      <div className={className} />
    );
  }
}

BeforeValue.propTypes = {
  value: PropTypes.number.isRequired,
  /* type: PropTypes.string.isRequired, */
};

export default BeforeValue;
