import {
  LOAD_ACCOUNTS_LIST, LOAD_ACCOUNTS, ADD_ACCOUNTS, EDIT_ACCOUNTS, ACCOUNTS_ERROR, MODIFY_MODAL, CLEAR, SET_ALL_ACCOUNTS,
} from '../actions/accountsActions';

const initialState = {
  order: 'asc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  defaultItem: {
    status: 'ACTIVE',
  },
  modal: false,
  filters: {},
  operations: ['edit'],
  headrows: [
    {
      id: 'name',
      type: 'value',
      label: 'Név',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'username',
      type: 'value',
      label: 'Felhasználónév',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'email',
      type: 'value',
      label: 'Email',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'device',
      type: 'value',
      label: 'Eszköz',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_ACCOUNTS_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case LOAD_ACCOUNTS: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case ADD_ACCOUNTS: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_ACCOUNTS: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case ACCOUNTS_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    case MODIFY_MODAL: {
      const { modal } = action;
      return {
        ...state,
        modal,
      };
    }
    case CLEAR:
      return {
        ...state,
        item: {},
        error: {},
      };
    case SET_ALL_ACCOUNTS: {
      const { all_accounts, all_accounts_filter } = action;
      return {
        ...state,
        all_accounts,
        all_accounts_filter,
      };
    }
    default:
      return state;
  }
};
