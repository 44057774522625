import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS';
export const LOAD_ACCOUNTS_LIST = 'LOAD_ACCOUNTS_LIST';
export const ADD_ACCOUNTS = 'ADD_ACCOUNTS';
export const EDIT_ACCOUNTS = 'EDIT_ACCOUNTS';
export const ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_ACCOUNTS = 'SET_ALL_ACCOUNTS';

export const loadAccounts = (accountsId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/accounts/${accountsId}`;

  if (accountsId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const accounts = res.data;
          const item = accounts;
          item.device = accounts.device.id;

          dispatch({
            type: LOAD_ACCOUNTS,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: ACCOUNTS_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_ACCOUNTS,
      item: getState().accounts.defaultItem,
    });
  }
};

export const loadAccountsList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/accounts${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'name');
        dispatch({
          type: LOAD_ACCOUNTS_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: ACCOUNTS_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: ACCOUNTS_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addAccounts = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts`, body);
    res.data.device = res.data.device.id;

    dispatch({
      type: ADD_ACCOUNTS,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadAccountsList());
  } catch (err) {
    dispatch({
      type: ACCOUNTS_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editAccounts = (accounts) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/accounts/${accounts.id}`;
  const body = JSON.stringify(accounts);

  try {
    const res = await axios.put(newUrl, body);
    res.data.device = res.data.device.id;

    dispatch({
      type: EDIT_ACCOUNTS,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadAccountsList());
  } catch (err) {
    dispatch({
      type: ACCOUNTS_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().accounts;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudAccounts'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allAccounts = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/accounts/all-accounts`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_accounts } = res.data;

        const allValue = [{ value: '__ALL__', label: 'Összes' }];
        const all_accounts_filter = allValue.concat(all_accounts);

        dispatch({
          type: SET_ALL_ACCOUNTS,
          all_accounts,
          all_accounts_filter,
        });
      }).catch((err) => {
        dispatch({
          type: ACCOUNTS_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: ACCOUNTS_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
