import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';

const SelectImage = class SelectImage extends PureComponent {
  constructor() {
    super();
    this.state = {
      src: '',
    };
  }

  setImage = (value) => {
    this.setState(() => ({ src: value }));
  }

  render() {
    const { fileList, firstImage } = this.props;
    let { src } = this.state;

    if (src === '') {
      src = firstImage;
    }

    return (
      <div className="camera-block">
        <h3 className="bold-text">Kamerakép</h3>
        <form className="form" noValidate>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="image"
                component={renderSelectFieldwithSelected}
                type="text"
                options={fileList}
                onChange={(value) => { this.setImage(value); }}
              />
            </div>
          </div>
        </form>

        <img
          src={src}
          alt=""
          className="img-responsive"
        />
      </div>
    );
  }
};

SelectImage.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  firstImage: PropTypes.string,
};

SelectImage.defaultProps = {
  fileList: [],
  firstImage: '',
};

const mapStateToProps = (state) => ({
  initialValues: state.track.item,
  fileList: state.track.item.fileList,
  firstImage: state.track.item.image,
});

const FormDecoratedComponent = reduxForm({
  form: 'selectImage',
  enableReinitialize: true,
})(SelectImage);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
