import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  valueContainer: (base) => ({
    ...base,
    height: '37px',
    position: 'none !important',
    flexWrap: 'nowrap !important',
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '12px !important',
  }),
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    height: '37px',
  }),
};

class MultiSelectField extends PureComponent {
  handleChange = (selectedOptions) => {
    let valuesArray = [];
    const { onChange } = this.props;

    if (selectedOptions !== null) {
      valuesArray = selectedOptions.map((obj) => obj.value);
    }

    onChange(valuesArray);
  };

  render() {
    const {
      value, name, placeholder, options,
    } = this.props;

    const currentValueArray = value;
    const currentValueObjList = [];

    // eslint-disable-next-line no-unused-vars
    Object.entries(options).forEach(([key, v]) => {
      if (currentValueArray.includes(v.value)) {
        currentValueObjList.push(v);
      }
    });

    return (
      <Select
        isMulti
        name={name}
        value={currentValueObjList}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        closeOnSelect={false}
        removeSelected={false}
        className="select"
        placeholder={placeholder}
        classNamePrefix="select-inside"
        styles={customStyles}
      />
    );
  }
}

MultiSelectField.defaultProps = {
  placeholder: '',
  options: [],
  value: [],
};

MultiSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  ]),
};

const renderMultiSelectField = (props) => {
  const {
    input, meta, options, placeholder,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <MultiSelectField
        {...input}
        options={options}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderMultiSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

renderMultiSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderMultiSelectField;
