import {
  LOAD_TASK_LIST,
  LOAD_TASK,
  ADD_TASK,
  EDIT_TASK,
  SET_TASK_FILES,
  TASK_ERROR,
  MODIFY_MODAL,
  CLEAR,
  SET_ALL_TASK,
} from '../actions/taskActions';

const initialState = {
  order: 'desc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  defaultItem: {
    status: 'NEW',
    priority: 'MEDIUM',
  },
  modal: false,
  filters: {},
  defaultFilters: {
    priority: '__ALL__',
    colleague: '__ALL__',
  },
  operations: ['edit', 'toggle'],
  headrows: [
    {
      id: 'id',
      type: 'value',
      label: 'Azon.',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'priority',
      type: 'value',
      label: 'Prio',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'company',
      type: 'value',
      label: 'Szerződés',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'account',
      type: 'value',
      label: 'Művezető',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'accounts',
      type: 'value',
      label: 'Munkatárs(ak)',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'date_from',
      type: 'value',
      label: 'Kezdete',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'date_to',
      type: 'value',
      label: 'Vége',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'address_task',
      type: 'value',
      label: 'Helyszín',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'task_type',
      type: 'value',
      label: 'Feladat',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'fulfillment',
      type: 'value',
      label: 'Teljesítve',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_TASK_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case LOAD_TASK: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case ADD_TASK: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_TASK: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case SET_TASK_FILES: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case TASK_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    case MODIFY_MODAL: {
      const { modal } = action;
      return {
        ...state,
        modal,
      };
    }
    case CLEAR:
      return {
        ...state,
        item: {},
        error: {},
      };
    case SET_ALL_TASK: {
      const { all_task } = action;
      return {
        ...state,
        all_task,
      };
    }
    default:
      return state;
  }
};
