import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { logout } from '../../../redux/actions/authActions';

function LogoutLink({
  title, icon, route, logoutClick,
}) {
  return (
    <NavLink
      to={route}
      onClick={(e) => logoutClick(e)}
      activeClassName="sidebar__link-active"
    >
      <li className="sidebar__link" title={title}>
        <SVG src={icon} />
      </li>
    </NavLink>
  );
}

LogoutLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  logoutClick: PropTypes.func.isRequired,
};

LogoutLink.defaultProps = {
  icon: '',
  route: '/',
};

const mapDispatchToProps = (dispatch) => ({
  logoutClick: (e) => {
    e.preventDefault();
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(LogoutLink);
