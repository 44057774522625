import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import Alertv2 from '../../../shared/components/Alertv2';
import renderSelectFieldwithSelected from '../../../shared/components/form/SelectWithSelected';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import FileList from '../../../shared/components/form/FileList';
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import { allPartners } from '../../../redux/actions/partnerActions';
import { deteteFile } from '../../../redux/actions/fileActions';
import {
  setContractFiles, addContract, editContract, modifyModal, loadContract,
} from '../../../redux/actions/contractActions';

const addRecordIcon = `${process.env.PUBLIC_URL}/img/AddRecordIcon.svg`;
const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const CRUDContract = class CRUDContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch, modal } = this.props;

    if (!modal) {
      dispatch(loadContract());
    }

    dispatch(allPartners());
    dispatch(modifyModal('crud'));
  }

  deleteFile = (id) => {
    const { itemId } = this.state;
    const { dispatch } = this.props;
    dispatch(deteteFile(id));
    dispatch(setContractFiles(itemId));
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      handleSubmit, modal, add, edit, companies, fileList, parentId, modalType,
    } = this.props;

    let modalTitle = 'Új szerződés';
    if (itemId !== '' && parentId === null) { modalTitle = `Szerződés szerkesztése #${itemId}`; }
    if (itemId === '' && parentId !== null) { modalTitle = 'Szerződésmódosítás létrehozása'; }
    if (itemId !== '' && parentId !== null) { modalTitle = `Szerződésmódosítás szerkesztése #${itemId}`; }

    return (
      <div>
        <div className="button-container">
          <button className="new-item" onClick={this.toggle} type="button">
            <span className="button-text">Hozzáad</span>
            <SVG src={addRecordIcon} />
          </button>
        </div>

        <Modal isOpen={modal && modalType === 'crud'} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            {modalTitle}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <form
              className="form"
              onSubmit={handleSubmit(itemId === '' ? add : edit)}
              noValidate
              encType="multipart/form-data"
            >
              <Field
                name="parent_id"
                component={RenderField}
                type="hidden"
              />

              <Container>
                <Alertv2 />

                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Cégnév</span>
                      <div className="form__form-group-field">
                        <Field
                          name="partner"
                          component={renderSelectFieldwithSelected}
                          type="text"
                          options={companies}
                          placeholder="Válasszon.."
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Szerződés tárgya</span>
                      <div className="form__form-group-field">
                        <Field
                          name="subject"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Szerződés kezdete</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_from"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={6} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Szerződés vége</span>
                      <div className="form__form-group-field">
                        <Field
                          name="date_to"
                          component={renderDatePickerField}
                          type="text"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Dokumentum feltöltése (excel, word, pdf)</span>
                      <div className="form__form-group-field">
                        <Field
                          name="files"
                          component={renderDropZoneMultipleField}
                          type="file"
                          accept="application/pdf, application/msword, .doc, .docx, .xlsx, .xls"
                        />
                      </div>
                    </div>
                  </Col>

                  {fileList.length > 0 && (
                    <Col md={6} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Feltöltött fájlok</span>
                        <FileList files={fileList} deleteFile={this.deleteFile} />
                      </div>
                    </Col>
                  )}
                </Row>

                <Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button className="submit-button" color="primary" type="submit">Mentés</Button>
                  </ButtonToolbar>
                </Row>
              </Container>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

CRUDContract.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  modalType: PropTypes.string,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
};

CRUDContract.defaultProps = {
  itemId: '',
  parentId: null,
  modalType: '',
  companies: [],
  fileList: [],
};

const mapStateToProps = (state) => ({
  initialValues: state.contract.item,
  modal: state.contract.modal,
  modalType: state.contract.modalType,
  itemId: state.contract.item.id,
  parentId: state.contract.item.parent_id,
  companies: state.partners.all_partners,
  fileList: state.contract.item.fileList,
});

const FormDecoratedComponent = reduxForm({
  form: 'crudContract',
  enableReinitialize: true,
})(CRUDContract);

const ConnectedAndFormDecoratedComponent = connect(
  mapStateToProps,
  { add: addContract, edit: editContract },
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
