import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default class DynamicTableHead extends PureComponent {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      headrows, order, orderBy, operations,
    } = this.props;

    return (
      <TableHead>
        <TableRow className="table-row">
          {headrows.map((row) => (
            <TableCell
              className="material-table__cell material-table__cell--sort"
              key={row.id}
              align="left"
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.showInHeader === true && row.label}
              {row.showInHeader === true
                && (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                />
                )}
            </TableCell>
          ), this)}

          {Object.keys(operations).length > 0 && (
            <TableCell
              className="material-table__cell material-table__cell--sort"
              key="operations"
              align="left"
              sortDirection={false}
            />
          )}

        </TableRow>
      </TableHead>
    );
  }
}

DynamicTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headrows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    showInHeader: PropTypes.bool,
  })).isRequired,
  operations: PropTypes.arrayOf(PropTypes.string).isRequired,
};
