import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, ListGroupItem,
} from 'reactstrap';
import SVG from 'react-inlinesvg';

const DeleteGrayIcon = `${process.env.PUBLIC_URL}/img/DeleteGrayIcon.svg`;

class FileList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { files, deleteFile } = this.props;

    return (
      <ListGroup className="file-list">
        {files.map((file) => (
          <li key={file.id} className="file-item">
            <ListGroupItem className="file-link" tag="a" href={file.link} target="_blank">{file.name}</ListGroupItem>
            <button className="delete-file" value={file.id} onClick={() => deleteFile(file.id)} type="button">
              <SVG src={DeleteGrayIcon} />
            </button>
          </li>
        ))}
      </ListGroup>
    );
  }
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteFile: PropTypes.func.isRequired,
};

export default FileList;
