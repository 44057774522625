import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { resetPassword } from '../../../../redux/actions/authActions';
import RenderField from '../../../../shared/components/form/RenderField';

class ResetForm extends PureComponent {
  render() {
    const {
      handleSubmit, isAuthenticated, resetting, t,
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit(resetting)}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('forms.form_reset.password')}</span>
          <div className="form__form-group-field password-field">
            <Field
              name="password"
              component={RenderField}
              type="password"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('forms.form_reset.retyped_password')}</span>
          <div className="form__form-group-field password-field">
            <Field
              name="retyped_password"
              component={RenderField}
              type="password"
            />
          </div>
        </div>
        <div className="account__btns">
          <Link className="btn btn-secondary account__btn" to="/login">{t('forms.form_reset.back_login')}</Link>
          <button className="btn btn-primary account__btn" type="submit">{t('forms.form_reset.submit')}</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  resetting: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { resetting: resetPassword })(reduxForm({
  form: 'reset_form',
})(withTranslation('common')(ResetForm)));
