import {
  LOAD_NOTICE_LIST, CLEAR_LIST, DECREASE_NOTICE_COUNTER, ADD_NOTICE, EDIT_NOTICE, NOTICE_ERROR,
} from '../actions/noticeActions';

const initialState = {
  totalCount: 0,
  list: [],
  error: {},
  counter: 0,
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_NOTICE_LIST: {
      const {
        payload: {
          list, totalCount, listCount,
        },
      } = action;

      return {
        ...state,
        list,
        counter: listCount,
        totalCount,
      };
    }
    case CLEAR_LIST: {
      const { list } = action;
      return {
        ...state,
        list,
      };
    }
    case DECREASE_NOTICE_COUNTER: {
      const { counter } = action;
      return {
        ...state,
        counter,
      };
    }
    case ADD_NOTICE: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_NOTICE: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case NOTICE_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    default:
      return state;
  }
};
