/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderInputField from '../../../shared/components/form/InputField';

let PartnerFilter = class PartnerFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleSubmit, filters: {
        name, contact_email, company, tax_number, contact_name, contact_phone,
      },
    } = this.props;

    return (
      <Col xs={12} md={2} lg={2} xl={2}>
        <Card>
          <CardBody>
            <div className="card-title">
              <h3 className="bold-text">szűrő</h3>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Név</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={renderInputField}
                    cValue={name}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">E-mail</span>
                <div className="form__form-group-field">
                  <Field
                    name="contact_email"
                    component={renderInputField}
                    cValue={contact_email}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Cég</span>
                <div className="form__form-group-field">
                  <Field
                    name="company"
                    component={renderInputField}
                    cValue={company}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Adószám</span>
                <div className="form__form-group-field">
                  <Field
                    name="tax_number"
                    component={renderInputField}
                    cValue={tax_number}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Kapcsolattartó</span>
                <div className="form__form-group-field">
                  <Field
                    name="contact_name"
                    component={renderInputField}
                    cValue={contact_name}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Kapcsolattartó telefonszám</span>
                <div className="form__form-group-field">
                  <Field
                    name="contact_phone"
                    component={renderInputField}
                    cValue={contact_phone}
                    type="text"
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button className="submit-button" color="primary" type="submit">Szűrés</Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
};

PartnerFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    contact_email: PropTypes.string,
    company: PropTypes.string,
    tax_number: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.partners.filters,
});

PartnerFilter = connect(mapStateToProps)(PartnerFilter);

export default reduxForm({
  form: 'partnerFilter',
})(PartnerFilter);
