import axios from 'axios';
import throwError from '../../shared/utility/errorHandler';
import { dateToString } from '../../shared/utility/dateConvert';

export const LOAD_NOTICE_LIST = 'LOAD_NOTICE_LIST';
export const ADD_NOTICE = 'ADD_NOTICE';
export const EDIT_NOTICE = 'EDIT_NOTICE';
export const NOTICE_ERROR = 'NOTICE_ERROR';
export const CLEAR_LIST = 'CLEAR_LIST';
export const DECREASE_NOTICE_COUNTER = 'DECREASE_NOTICE_COUNTER';

export const clearList = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LIST,
    list: [],
  });
};
export const decreaseNoticeCounter = () => async (dispatch, getState) => {
  const { counter } = getState().notice;
  const newCounter = counter - 1;

  dispatch({
    type: DECREASE_NOTICE_COUNTER,
    counter: newCounter,
  });
};

export const loadNoticeList = () => async (dispatch) => {
  const today = dateToString(Date.now());
  const path = `${process.env.REACT_APP_API_URL}/api/notices?f-create_date=${today}&f-status=NEW&rowsPerPage=3`;

  try {
    axios.get(path)
      .then((res) => {
        const payload = res.data;
        dispatch({
          type: LOAD_NOTICE_LIST,
          payload,
        });
      }).catch((err) => {
        dispatch({
          type: NOTICE_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: NOTICE_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addNotice = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/notices`, body);

    dispatch({
      type: ADD_NOTICE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NOTICE_ERROR,
      payload: { msg: err.response.data },
    });

    throwError(err.response.data);
  }
};

export const editNotice = (notice) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/notices/${notice.id}`;
  const body = JSON.stringify(notice);

  try {
    const res = await axios.put(newUrl, body);

    dispatch({
      type: EDIT_NOTICE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NOTICE_ERROR,
      payload: { msg: err.response.data },
    });

    throwError(err.response.data);
  }
};
