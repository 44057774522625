import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const LOAD_ADDRESS_LIST = 'LOAD_ADDRESS_LIST';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const ADDRESS_ERROR = 'ADDRESS_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_ADDRESSES = 'SET_ALL_ADDRESSES';

export const loadAddress = (addressId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/addresses/${addressId}`;

  if (addressId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const address = res.data;
          const item = address;
          item.partner = address.partner.id;

          dispatch({
            type: LOAD_ADDRESS,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: ADDRESS_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_ADDRESS,
      item: getState().address.defaultItem,
    });
  }
};

export const loadAddressList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/addresses${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'company');
        dispatch({
          type: LOAD_ADDRESS_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: ADDRESS_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: ADDRESS_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addAddress = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/addresses`, body);
    res.data.partner = res.data.partner.id;

    dispatch({
      type: ADD_ADDRESS,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadAddressList());
  } catch (err) {
    dispatch({
      type: ADDRESS_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editAddress = (address) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/addresses/${address.id}`;
  const body = JSON.stringify(address);

  try {
    const res = await axios.put(newUrl, body);
    res.data.partner = res.data.partner.id;

    dispatch({
      type: EDIT_ADDRESS,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadAddressList());
  } catch (err) {
    dispatch({
      type: ADDRESS_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().address;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudAddress'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};

export const allAddresses = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/addresses/all-addresses`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_addresses } = res.data;

        dispatch({
          type: SET_ALL_ADDRESSES,
          all_addresses,
        });
      }).catch((err) => {
        dispatch({
          type: ADDRESS_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: ADDRESS_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
