import {
  SET_CONTRACT_MODIFICATION_DEFAULT_VALUES,
  LOAD_CONTRACT_LIST,
  LOAD_CONTRACT,
  ADD_CONTRACT,
  EDIT_CONTRACT,
  SET_CONTRACT_FILES,
  CONTRACT_ERROR,
  MODIFY_MODAL,
  CLEAR,
  SET_ALL_CONTRACTS,
  SET_CONTRACT_EXPORT,
} from '../actions/contractActions';

const initialState = {
  order: 'desc',
  orderBy: '',
  totalCount: 0,
  rowsPerPage: 10,
  page: 0,
  item: {},
  defaultItem: {},
  modal: false,
  filters: {},
  operations: ['edit', 'toggle', 'add', 'excelExport'],
  headrows: [
    {
      id: 'id',
      type: 'value',
      label: 'Azon.',
      class: 'first',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'company',
      type: 'value',
      label: 'Cég neve',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'head_office',
      type: 'value',
      label: 'Partner székhelye',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'subject',
      type: 'value',
      label: 'Szerződés tárgya',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'date_from',
      type: 'value',
      label: 'Kezdete',
      class: '',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
    {
      id: 'date_to',
      type: 'value',
      label: 'Vége',
      class: 'last',
      showInHeader: true,
      valueBefore: '',
      valueAfter: '',
    },
  ],
  data: [],
  error: {},
};

export default (state = initialState, action = null) => {
  const { type } = action;

  switch (type) {
    case LOAD_CONTRACT_LIST: {
      const {
        payload: {
          order, orderBy, data, totalCount, rowsPerPage, page,
        }, filters,
      } = action;

      return {
        ...state,
        order,
        orderBy,
        rowsPerPage,
        page,
        headrows: state.headrows,
        rowMenus: state.rowMenus,
        filters,
        data,
        totalCount,
      };
    }
    case SET_CONTRACT_MODIFICATION_DEFAULT_VALUES: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case LOAD_CONTRACT: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case ADD_CONTRACT: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case EDIT_CONTRACT: {
      const { payload } = action;
      return {
        ...state,
        item: payload,
      };
    }
    case SET_CONTRACT_FILES: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case SET_CONTRACT_EXPORT: {
      const { item } = action;
      return {
        ...state,
        item,
      };
    }
    case CONTRACT_ERROR: {
      const { payload } = action;
      return {
        ...state,
        error: {
          payload,
        },
      };
    }
    case MODIFY_MODAL: {
      const { modal, modalType } = action;
      return {
        ...state,
        modal,
        modalType,
      };
    }
    case CLEAR:
      return {
        ...state,
        item: {},
        error: {},
      };
    case SET_ALL_CONTRACTS: {
      const { all_contracts } = action;
      return {
        ...state,
        all_contracts,
      };
    }
    default:
      return state;
  }
};
