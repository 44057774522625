import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const SET_CONTRACT_MODIFICATION_DEFAULT_VALUES = 'SET_CONTRACT_MODIFICATION_DEFAULT_VALUES';
export const LOAD_CONTRACT = 'LOAD_CONTRACT';
export const LOAD_CONTRACT_LIST = 'LOAD_CONTRACT_LIST';
export const ADD_CONTRACT = 'ADD_CONTRACT';
export const EDIT_CONTRACT = 'EDIT_CONTRACT';
export const SET_CONTRACT_FILES = 'SET_CONTRACT_FILES';
export const CONTRACT_ERROR = 'CONTRACT_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';
export const SET_ALL_CONTRACTS = 'SET_ALL_CONTRACTS';
export const SET_CONTRACT_EXPORT = 'SET_CONTRACT_EXPORT';

const configFile = {
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
};

export const setContractModificationDefaultValues = (id) => async (dispatch) => {
  const item = {};
  item.parent_id = id;

  dispatch({
    type: LOAD_CONTRACT,
    item,
  });
};

export const setContractFiles = (contractId) => async (dispatch, getState) => {
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=contract&f-table_id=${contractId}&f-file_type=document&rowsPerPage=200`;
  const files = await axios.get(filesPath);
  const { item } = getState().contract;

  item.fileList = files.data.list;

  dispatch({
    type: SET_CONTRACT_FILES,
    item,
  });
};

export const loadContract = (contractId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/contracts/${contractId}`;
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=contract&f-table_id=${contractId}&f-file_type=document&rowsPerPage=200`;

  if (contractId !== '') {
    try {
      axios.get(filesPath).then((files) => {
        axios.get(path).then((res) => {
          const contract = res.data;
          const item = contract;

          item.partner = contract.partner.id;
          item.fileList = files.data.list;

          dispatch({
            type: LOAD_CONTRACT,
            item,
          });
        });
      });
      return;
    } catch (err) {
      dispatch({
        type: CONTRACT_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_CONTRACT,
      item: getState().contract.defaultItem,
    });
  }
};

export const loadContractList = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/contracts${window.location.search}`;
  const filters = getFilterFromUrl();

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'date_from', 'desc');
        dispatch({
          type: LOAD_CONTRACT_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: CONTRACT_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: CONTRACT_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addContract = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/contracts`, body);
    res.data.partner = res.data.partner.id;
    const contractId = res.data.id;
    const fileUrl = `${process.env.REACT_APP_API_URL}/api/files/contract/${res.data.id}/document`;

    if (typeof post.files !== 'undefined') {
      const { files } = post;

      const uploaders = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', 'codeinfuse, medium, gist');
        formData.append('timestamp', (Date.now() / 1000) || 0);

        return axios.post(fileUrl, formData, configFile)
          .then(() => {
          }).catch((error) => {
            dispatch(setAlert(`${file.name} - ${error.response.data.detail}`, 'danger', 20000));
          });
      });

      axios.all(uploaders).then(() => {
        dispatch(setContractFiles(contractId));
      });
    }

    dispatch({
      type: ADD_CONTRACT,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres űrlap mentés!', 'success'));
    dispatch(loadContractList());
  } catch (err) {
    dispatch({
      type: CONTRACT_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editContract = (contract) => async (dispatch) => {
  const dataUrl = `${process.env.REACT_APP_API_URL}/api/contracts/${contract.id}`;
  const fileUrl = `${process.env.REACT_APP_API_URL}/api/files/contract/${contract.id}/document`;

  const body = JSON.stringify(contract);

  try {
    const res = await axios.put(dataUrl, body);
    res.data.partner = res.data.partner.id;

    if (typeof contract.files !== 'undefined') {
      const { files } = contract;

      const uploaders = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', 'codeinfuse, medium, gist');
        formData.append('timestamp', (Date.now() / 1000) || 0);

        return axios.post(fileUrl, formData, configFile)
          .then(() => {
          }).catch((error) => {
            dispatch(setAlert(`${file.name} - ${error.response.data.detail}`, 'danger', 20000));
          });
      });

      axios.all(uploaders).then(() => {
        dispatch(setContractFiles(contract.id));
      });
    } else {
      dispatch(setContractFiles(contract.id));
    }

    dispatch({
      type: EDIT_CONTRACT,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres űrlap mentés!', 'success'));
    dispatch(loadContractList());
  } catch (err) {
    dispatch({
      type: CONTRACT_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = (modalType) => async (dispatch, getState) => {
  const { modal } = getState().contract;
  let newModalType = modalType;

  if (modal === true) {
    newModalType = '';
    dispatch(clear());
    dispatch(reset('crudContract'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
    modalType: newModalType,
  });
};

export const allContracts = () => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/contracts/all-contracts`;
  try {
    axios.get(path)
      .then((res) => {
        const { all_contracts } = res.data;

        dispatch({
          type: SET_ALL_CONTRACTS,
          all_contracts,
        });
      }).catch((err) => {
        dispatch({
          type: CONTRACT_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: CONTRACT_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const setContractExport = (contractId) => async (dispatch, getState) => {
  // eslint-disable-next-line max-len
  const filesPath = `${process.env.REACT_APP_API_URL}/api/files?f-table_name=contract&f-table_id=${contractId}&f-file_type=excel-export`;
  const files = await axios.get(filesPath);
  const { item } = getState().contract;

  item.exportList = files.data.list;

  dispatch({
    type: SET_CONTRACT_EXPORT,
    item,
  });
};

export const contractExportExcel = (contractId) => async (dispatch) => {
  const path = `${process.env.REACT_APP_API_URL}/api/contracts/excel-export?contract_id=${contractId}`;

  try {
    axios.get(path).then(() => {
      dispatch(setContractExport(contractId));
    });
    return;
  } catch (err) {
    dispatch({
      type: CONTRACT_ERROR,
      payload: { msg: err.response.data },
    });
  }
};
