import axios from 'axios';
import { reset } from 'redux-form';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';
import { getFilterFromUrl, setTableSettings } from '../../shared/utility/urlModify';

export const LOAD_SUB_TASK = 'LOAD_SUB_TASK';
export const LOAD_SUB_TASK_LIST = 'LOAD_SUB_TASK_LIST';
export const ADD_SUB_TASK = 'ADD_SUB_TASK';
export const EDIT_SUB_TASK = 'EDIT_SUB_TASK';
export const SUB_TASK_ERROR = 'SUB_TASK_ERROR';
export const MODIFY_MODAL = 'MODIFY_MODAL';
export const CLEAR = 'CLEAR';

export const loadSubTask = (subTaskId = '') => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/sub-tasks/${subTaskId}`;

  if (subTaskId !== '') {
    try {
      axios.get(path)
        .then((res) => {
          const item = res.data;

          dispatch({
            type: LOAD_SUB_TASK,
            item,
          });
        });
      return;
    } catch (err) {
      dispatch({
        type: SUB_TASK_ERROR,
        payload: { msg: err.response.data },
      });
    }
  } else {
    dispatch({
      type: LOAD_SUB_TASK,
      item: getState().subTask.defaultItem,
    });
  }
};

export const loadSubTaskList = () => async (dispatch, getState) => {
  const path = `${process.env.REACT_APP_API_URL}/api/sub-tasks${window.location.search}`;
  const { defaultFilters } = getState().subTask;
  const defaultFiltersClone = { ...defaultFilters };
  const filters = Object.assign(defaultFiltersClone, getFilterFromUrl());

  try {
    axios.get(path)
      .then((res) => {
        const payload = setTableSettings(res.data, 'date_task');
        dispatch({
          type: LOAD_SUB_TASK_LIST,
          payload,
          filters,
        });
      }).catch((err) => {
        dispatch({
          type: SUB_TASK_ERROR,
          payload: { msg: err.response.data },
        });
      });
  } catch (err) {
    dispatch({
      type: SUB_TASK_ERROR,
      payload: { msg: err.response.data },
    });
  }
};

export const addSubTask = (post) => async (dispatch) => {
  const body = JSON.stringify(post);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/sub-tasks`, body);

    dispatch({
      type: ADD_SUB_TASK,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadSubTaskList());
  } catch (err) {
    dispatch({
      type: SUB_TASK_ERROR,
      payload: { msg: err.response.data },
    });

    switch (err.response.status) {
      case 403:
        dispatch(setAlert('Hozzáférés megtagadva!', 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const editSubTask = (subTask) => async (dispatch) => {
  const newUrl = `${process.env.REACT_APP_API_URL}/api/sub-tasks/${subTask.id}`;
  const body = JSON.stringify(subTask);

  try {
    const res = await axios.put(newUrl, body);

    dispatch({
      type: EDIT_SUB_TASK,
      payload: res.data,
    });
    dispatch(setAlert('Sikeres mentés!', 'success'));
    dispatch(loadSubTaskList());
  } catch (err) {
    dispatch({
      type: SUB_TASK_ERROR,
      payload: { msg: err.response.data },
    });
    dispatch(setAlert('Sikertelen mentés!', 'danger'));

    throwError(err.response.data);
  }
};

export function clear() {
  return {
    type: CLEAR,
  };
}

export const modifyModal = () => async (dispatch, getState) => {
  const { modal } = getState().subTask;

  if (modal === true) {
    dispatch(clear());
    dispatch(reset('crudSubTask'));
  }

  dispatch({
    type: MODIFY_MODAL,
    modal: !modal,
  });
};
