import React from 'react';
import {
  Container, Col, Row, Modal, ModalHeader, ModalBody, ButtonToolbar, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import FileList from '../../../shared/components/form/FileList';
import { modifyModal, contractExportExcel, setContractExport } from '../../../redux/actions/contractActions';
import { deteteFile } from '../../../redux/actions/fileActions';

const closeModalIcon = `${process.env.PUBLIC_URL}/img/CloseModalIcon.svg`;

const ExportContract = class CRUDContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevState) {
    const { itemId } = this.props;
    if (prevState.itemId !== itemId) {
      this.setState({
        itemId,
      });
    }
  }

  toggle = () => {
    this.setState(() => ({
      itemId: '',
    }));
    const { dispatch } = this.props;

    dispatch(modifyModal('export'));
  }

  export = (id) => {
    const { dispatch } = this.props;
    dispatch(contractExportExcel(id));
  }

  deleteFile = (id) => {
    const { dispatch } = this.props;
    const { itemId } = this.state;

    dispatch(deteteFile(id));
    dispatch(setContractExport(itemId));
  }

  render() {
    const {
      itemId,
    } = this.state;

    const {
      modal, fileList, modalType,
    } = this.props;

    return (
      <div>
        <Modal isOpen={modal && modalType === 'export'} toggle={this.toggle} className="form-modal">
          <ModalHeader toggle={this.toggle}>
            Szerződés exportálása #{itemId}
            <button type="button" className="close-btn" aria-label="Close" onClick={this.toggle}>
              <SVG src={closeModalIcon} />
            </button>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                {fileList.length > 0 && (
                <Col md={6} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Exportált szerződések</span>
                    <FileList files={fileList} deleteFile={this.deleteFile} />
                  </div>
                </Col>
                )}
              </Row>

              <Row>
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    className="submit-button"
                    color="primary"
                    type="button"
                    onClick={() => this.export(itemId)}
                  >
                    Export
                  </Button>
                </ButtonToolbar>
              </Row>
            </Container>

          </ModalBody>
        </Modal>
      </div>
    );
  }
};

ExportContract.defaultProps = {
  itemId: '',
  modalType: '',
  fileList: [],
};

ExportContract.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  modalType: PropTypes.string,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  modal: state.contract.modal,
  modalType: state.contract.modalType,
  fileList: state.contract.item.exportList,
});

export default connect(mapStateToProps)(ExportContract);
