import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  authReducer,
  partnerReducer,
  addressReducer,
  accountsReducer,
  materialReducer,
  deviceReducer,
  taskTypeReducer,
  unitReducer,
  contractReducer,
  taskReducer,
  subTaskReducer,
  alertReducer,
  trackReducer,
  noticeReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  auth: authReducer,
  form: reduxFormReducer,
  partners: partnerReducer,
  address: addressReducer,
  accounts: accountsReducer,
  material: materialReducer,
  device: deviceReducer,
  taskType: taskTypeReducer,
  unit: unitReducer,
  contract: contractReducer,
  task: taskReducer,
  subTask: subTaskReducer,
  alert: alertReducer,
  track: trackReducer,
  notice: noticeReducer,
});

const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
