import React, { Component } from 'react';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Sidebar from './sidebar/Sidebar';
import { BasicNotification } from '../../shared/components/Notification';
import {
  loadNoticeList, editNotice, clearList, decreaseNoticeCounter,
} from '../../redux/actions/noticeActions';

let notification = null;

const showNotification = (id, title, message, dispatch) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
    />,
    onClose() {
      const notice = {};
      notice.id = id;
      notice.status = 'CLOSED';
      dispatch(editNotice(notice));
      dispatch(decreaseNoticeCounter(notice));
    },
    duration: null,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'left-up',
  });
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return;
    }

    NotificationSystem.newInstance({}, (n) => { notification = n; });

    setInterval(() => {
      const { dispatch, counter, list } = this.props;

      if (counter <= 0) {
        dispatch(loadNoticeList());
      }

      if (list.length > 0) {
        list.map((notice) => {
          const title = `${notice.title} ${notice.create_date}`;
          const message = notice.name !== null ? `${notice.name} ${notice.description}` : notice.description;

          setTimeout(
            () => showNotification(notice.id, title, message, dispatch),
            1000,
          );
          return true;
        });
        dispatch(clearList());
      }
    }, 30000);
  }

  render() {
    return (
      <div className="layout">
        <Sidebar />
      </div>
    );
  }
}

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  counter: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  list: state.notice.list,
  counter: state.notice.counter,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Layout);
