import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  valueContainer: (base) => ({
    ...base,
    height: '37px',
    position: 'none !important',
  }),
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    height: '37px',
  }),
};

class SelectField extends PureComponent {
  handleChange = (selectedOption) => {
    const decorated = selectedOption.value;
    const { onChange } = this.props;
    if (decorated !== '') {
      onChange(decorated);
    } else {
      onChange(null);
    }
  };

  render() {
    const {
      value, name, placeholder, options, defaultValue,
    } = this.props;

    let newOptions = options;
    if (defaultValue !== '') {
      const emptyValue = [defaultValue];
      newOptions = emptyValue.concat(options);
    }

    let newValue = defaultValue;
    let newDefaultValue = defaultValue;
    if (value !== '') {
      newValue = options.find((obj) => obj.value === value);
      newDefaultValue = null;
    }

    return (
      <Select
        name={name}
        value={newValue}
        defaultValue={newDefaultValue}
        onChange={this.handleChange}
        options={newOptions}
        clearable={false}
        className="select"
        placeholder={placeholder}
        classNamePrefix="select-inside"
        styles={customStyles}
      />
    );
  }
}

const renderSelectFieldwithSelected = (props) => {
  const {
    input, meta, options, placeholder, defaultValue,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).isRequired,
};

SelectField.defaultProps = {
  placeholder: '',
  options: [],
  defaultValue: '',
};

renderSelectFieldwithSelected.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
};

renderSelectFieldwithSelected.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  defaultValue: '',
};

export default renderSelectFieldwithSelected;
