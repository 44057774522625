import axios from 'axios';

const setHeader = () => {
  axios.defaults.headers.common = {
    Accept: 'application/json',
    // 'Content-type': 'application/json',
  };
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';
};

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export {
  setHeader,
  setAuthToken,
};
