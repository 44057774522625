import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InputField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cValue: props.cValue,
    };
  }

  componentDidMount() {
    const { input: { onChange }, cValue } = this.props;
    this.setState({
      cValue,
    });
    onChange(cValue);
  }

  componentDidUpdate(prevState) {
    const { input: { onChange }, cValue } = this.props;
    if (prevState.cValue !== cValue) {
      this.setState({
        cValue,
      });
      onChange(cValue);
    }
  }

  handleChange = (event) => {
    const { input: { onChange } } = this.props;
    const { value } = event.target;
    this.setState({
      cValue: value,
    });
    onChange(value);
  }

  render() {
    const {
      input: { name }, type,
    } = this.props;
    const { cValue } = this.state;

    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <input name={name} value={cValue} type={type} onChange={(event) => this.handleChange(event)} />
      </div>
    );
  }
}

const renderInputField = (props) => {
  const {
    input, cValue, type,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <InputField
        {...input}
        cValue={cValue}
        type={type}
      />
    </div>
  );
};

InputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  cValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

renderInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  cValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

InputField.defaultProps = {
  cValue: '',
  type: 'text',
};

renderInputField.defaultProps = {
  cValue: '',
  type: 'text',
};

export default InputField;
